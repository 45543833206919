import _ from 'lodash';
import {toast} from "react-toastify";

export const checkError = (nextProps: any, curProps: any, onSuccess: Function) => {

    if (nextProps.error && !_.isEqual(nextProps.error, curProps.error)) {
        console.log(nextProps.error);
        if (nextProps.error.error) {
            if (nextProps.error.error.original) {
                toast.error(nextProps.error.error.original.detail, {
                    position: toast.POSITION.TOP_LEFT
                });
            } else {
                if (typeof nextProps.error.error === "string") {
                    toast.error(nextProps.error.error, {
                        position: toast.POSITION.TOP_LEFT
                    });
                } else {
                    toast.error(nextProps.error.message, {
                        position: toast.POSITION.TOP_LEFT
                    });
                }
            }
        } else {
            toast.error(nextProps.error.error || nextProps.error.message, {
                position: toast.POSITION.TOP_LEFT
            });
        }
        onSuccess();
    }
}
