import React, {Component} from 'react';

import * as PropTypes from 'prop-types';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';


const _KeyboardDatePicker: any = KeyboardDatePicker;


const DateEditorBase = ({value, onValueChange}: any) => {
    const dateRange: any = {};
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        null,
    );
    const [endDate, setendDate] = React.useState<Date | null>(
        null,
    );

    function handleDateChange(date: Date | null) {
        setSelectedDate(date);
        if (date) dateRange['start'] = new Date(date).toISOString();
        onValueChange(date ? dateRangeS(dateRange) : null);
    }

    function handleEndDateChange(date: Date | null) {
        setendDate(date);
        if (date) dateRange['end'] = new Date(date).toISOString();
        onValueChange(date ? dateRangeS(dateRange) : null);
    }

    function dateRangeS(dateRange: any) {
        const res: any = {};
        if (selectedDate) res['start'] = new Date(selectedDate).toISOString();
        if (endDate) res['end'] = new Date(endDate).toISOString();
        return {
            ...res,
            ...dateRange
        };
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around" style={{flexDirection: 'row'}}>
                <Tooltip title="Start from">
                    <div style={{maxWidth: '45%', marginRight: 5}}>
                        <_KeyboardDatePicker

                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                    </div>
                </Tooltip>
                <Tooltip title="End to">
                    <div style={{maxWidth: '50%'}}>
                        <_KeyboardDatePicker

                            value={endDate}
                            onChange={handleEndDateChange}
                        />
                    </div>
                </Tooltip>
            </Grid>

        </MuiPickersUtilsProvider>
    );
};

DateEditorBase.propTypes = {
    value: PropTypes.number,
    onValueChange: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

DateEditorBase.defaultProps = {
    value: undefined,
};

export default DateEditorBase;
