import React, {Component} from 'react';
import {
    moduleName
} from "../../../../../ducks/map";
import {bindActionCreators} from "redux";
import {showDialogContent} from "../../../../../ducks/dialogs";
import {connect} from "react-redux";
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import SearchIconStatic from "../../../../../assets/img/search.svg";
import ReactSVG from 'react-svg'


interface MapProjectsProps {
    title: string,
    list: any,
    project: any,
    onChange: Function,
    showDialogContent: any,
}

class DialogList extends Component<MapProjectsProps,
    { search: string }> {

    state = {
        search: ''
    };


    private onSearch = (e: any) => {
        this.setState({
            search: e.target.value
        })
    };

    render() {
        const {title, list, project} = this.props;
        let _list = list;
        if (this.state.search) {
            const search = this.state.search.toLowerCase();
            let keys = Object.keys(list[0]);
            _list = _list.filter((el: any) => {
                for (let i = 0; i < keys.length; i++) {
                    if (el[keys[i]] && el[keys[i]].toString().toLowerCase().match(search)) {
                        return true;
                    }
                }
                return false;
            })
        }
        return (
            <div className={'modal-container'}>
                <IconButton className={'close'} onClick={() => this.props.showDialogContent(null)}>
                    <Icon style={{color: '#fff'}}>close</Icon>
                </IconButton>
                <div className={'modal-title'}>Wybierz {title}</div>
                <div className={'modal-title'}>
                    <div className={"search-input d-flex a-c j-center"}>
                        <ReactSVG src={SearchIconStatic} className={'svg-icon'}/>
                        <input
                            placeholder={'Search project...'}
                            style={{borderBottom: '1px solid white', backgroundColor: '#282c34', color: '#fff'}}
                            onChange={this.onSearch}
                        />
                    </div>
                </div>

                <div className={'modal-body'}>
                    {
                        _list.map((el: any) => {
                            return <div
                                className={`modal-option text-overflow ${project && el.id === project.id ? 'active' : ''}`}
                                title={el.title} key={el.id}
                                onClick={() => this.props.onChange(el)}>{el.title}</div>
                        })
                    }
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
        error: state[moduleName].error,
    }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showDialogContent,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(DialogList);
