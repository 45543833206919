import {statuses} from "../../../../utils";
import {SETTINGS} from "../../../../entities/utils";

declare var google: any, createPopupClass: any, MarkerClusterer: any;


export const createPolygon = function (path: any, settings: any = {status: statuses[0].value}) {
    let polygon = new google.maps.Polygon({
        path: path,
        ...SETTINGS.ACTIVE.POLYGON,
        fillOpacity: settings.status === statuses[2].value ? 0.3 : 0,
        strokeWeight: settings.status === statuses[2].value ? 4 : 2,
        strokeColor: settings.status === statuses[0].value ? 'blue' : (settings.status === statuses[1].value ? 'green' : 'red'),
        fillColor: settings.status === statuses[0].value ? 'blue' : (settings.status === statuses[1].value ? 'green' : 'red')
    });

    let lastPath: any = null,
        lastCenter: any = null;
    polygon.getPosition = function () {
        var path = this.getPath();
        if (lastPath == path) {
            return lastCenter;
        }
        lastPath = path;
        var bounds = new google.maps.LatLngBounds();
        path.forEach(function (latlng: any, i: any) {
            bounds.extend(latlng);
        });

        lastCenter = bounds.getCenter()
        return lastCenter;
    };
    return polygon;
}

export const createPath = function (el: any) {

    let lastPath: any = null,
        lastCenter: any = null;
    el.getPosition = function () {
        var path = this.getPath();
        if (lastPath == path) {
            return lastCenter;
        }
        lastPath = path;
        var bounds = new google.maps.LatLngBounds();
        path.forEach(function (latlng: any, i: any) {
            bounds.extend(latlng);
        });

        lastCenter = bounds.getCenter()
        return lastCenter;
    };
    return el;
}

export const createCircle = function (path: any) {
    let lastPath: any = null,
        lastCenter: any = null;
    path.getPosition = function () {
        const latlng = this.center;
        var path = latlng;
        if (lastPath == path) {
            return lastCenter;
        }
        lastPath = path;
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(latlng);

        lastCenter = bounds.getCenter()
        return lastCenter;
    };
    return path;
}

export const createMarker = function (path: any) {
    let lastPath: any = null,
        lastCenter: any = null;
    path.getPosition = function () {
        const latlng = this.position;
        var path = latlng;
        if (lastPath == path) {
            return lastCenter;
        }
        lastPath = path;
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(latlng);

        lastCenter = bounds.getCenter()
        return lastCenter;
    };
    return path;
}
