import React, {Component} from 'react';

import './index.scss';
import moment from "moment";
import Title from "../title";


export default function DateFormater({children}: any) {
    const date = moment(children).format('l');
    return (
        <Title>{date}</Title>
    )
}
