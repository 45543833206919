import React, {Component} from 'react';

import './index.scss';

import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class DeleteItemDialog extends Component<{ title: string, onCancel: Function, onAccept: Function }, { refreshing: boolean }> {

    state = {
        refreshing: false,
    };


    private onCancel = () => {
        this.props.onCancel();
    };
    private onAccept = () => {
        this.props.onAccept();
    };

    render() {
        return (
            <React.Fragment>
                <DialogTitle
                    className={'modal-container'}>
                    <div className={'modal-title'}>Are you sure to delete {this.props.title}</div>
                    <IconButton className={'close'} onClick={this.onCancel}>
                        <Icon>close</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogActions>
                    <Button onClick={this.onCancel} color="primary">
                        Cancel
                    </Button>
                    <Button  variant="contained" className={'my-btn btn-primary'} onClick={this.onAccept}>
                        OK
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }
}

