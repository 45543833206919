import React, {Component} from 'react';
import './index.scss';
import {
    changeControls,
    currentModeSelector,
    lastGeoPostionsSelector,
    locationParcelsSelector,
    locationPoisSelector,
    locationPolesSelector,
    locationSegmentsSelector,
    locationSelector,
    locationsSelector, locationStationsSelector,
    modesSelector,
    moduleName
} from "../../../../ducks/map";
import {bindActionCreators} from "redux";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {connect} from "react-redux";
import Divider from '@material-ui/core/Divider';
import moment from 'moment';

import {Parcel, Pole, Segment, Station} from "../../../../entities";

interface MapProjectsProps {
    poles: Array<Pole>,
    segments: Array<Segment>,
    parcels: Array<Parcel>,
    stations: Array<Station>,
    changeControls: Function,
    dateFilter: any,
    showStations: boolean,
    showSegments: boolean,
    showParcels: boolean,
    showPoles: boolean
}

class RecordUpdatedFilter extends Component<MapProjectsProps,
    { dates: Array<any> }> {

    state = {
        dates: [
            {
                title: (<p className={'option-available'}>Dzisiaj&nbsp;<span
                    className={'text-light'}>{moment().format('l')}</span></p>),
                value: moment().utc().toString()
            },
            {
                title: (<p className={'option-available'}>Ostatnie 7 dni&nbsp;
                    <span className={'text-light'}>{moment().subtract(7, 'days').format('l')}</span></p>),
                value: moment().subtract(7, 'days').utc().toString()
            },
            {
                title: (<p className={'option-available'}>Ostatnie 30 dni&nbsp;
                    <span className={'text-light'}>{moment().subtract(30, 'days').format('l')}</span></p>),
                value: moment().subtract(30, 'days').utc().toString()
            },
        ]
    };


    private handleChange = (name: string) => {
        return (e: any) => {
            this.props.changeControls({name, value: e.target.value})
        }
    };

    render() {
        const {
            dateFilter
        } = this.props;
        const {dates} = this.state;

        return (
            <React.Fragment>
                <div className={'d-flex f-col entities-info'}>
                    <FormControl>
                        <FormLabel component="legend"><span className={'text-light'}>Aktualizacja rekordów:</span></FormLabel>
                        <RadioGroup
                            aria-label="Record Updated"
                            name="RecordUpdated"
                            value={dateFilter}
                            onChange={this.handleChange('dateFilter')}
                        >
                            <FormControlLabel value="All" checked={dateFilter === 'All'}
                                              className={`radio-item ${dateFilter === 'All' ? 'checked fon-bold' : ''}`}
                                              control={<Radio color="primary"/>} label={<p className={'option-available'}>Wszystkie</p>}
                                              color={'primary'}/>
                            {
                                dates.map((el: any) => (
                                    <FormControlLabel checked={dateFilter === el.value} value={el.value}
                                                      className={`radio-item ${dateFilter === el.value ? 'checked fon-bold' : ' '}`}
                                                      control={<Radio color="primary"/>} label={el.title} key={el.value}
                                    />
                                ))
                            }


                        </RadioGroup>
                    </FormControl>
                </div>
                <Divider/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        dateFilter: state[moduleName].dateFilter,
        showStations: state[moduleName].showStations,
        showSegments: state[moduleName].showSegments,
        showParcels: state[moduleName].showParcels,
        showPoles: state[moduleName].showPoles,
        pois: locationPoisSelector(state),
        segments: locationSegmentsSelector(state),
        poles: locationPolesSelector(state),
        parcels: locationParcelsSelector(state),
        stations: locationStationsSelector(state),
        error: state[moduleName].error,
    }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        changeControls: changeControls
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(RecordUpdatedFilter);
