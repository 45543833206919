import React, {Component} from 'react';
import './index.scss';
import {errorSelector, locationSelector, moduleName} from "../../../../../ducks/map";
import {isSuperADMINAdminSelector, isSuperAdminSelector} from "../../../../../ducks/auth";
import {showDialogContent} from "../../../../../ducks/dialogs";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {editStation} from "../../../../../ducks/map/stations";
import MainModalDialog, {TYPES} from "../main.modal";


class AddStationDialog extends MainModalDialog {

    constructor(p: any) {
        super(p);
        this.title = 'Stacje';
        this.type = TYPES.STATION;
        this.editTitle = false;
    }

    render() {

        return super._render();
    }
}

const mapStateToProps = (state: any) => ({
    itemsList: state[moduleName].stationList,
    error: errorSelector(state),
    location: locationSelector(state),
    isAdmin: isSuperADMINAdminSelector(state),
});

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showDialogContent,
        editItem: editStation,
    }, dispatch)
);
const edit = connect(mapStateToProps, mapDispatchToProps)(AddStationDialog);
export default edit;
