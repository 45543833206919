import React, {Component} from 'react';
import './index.scss';

import {signOut, userSelector} from '../../ducks/auth';
import {fetchUsers} from '../../ducks/admin/users';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import UsersPage from "./users";
import LogsPageProps from "./logs";
import CategoryTable from "./category";
import {Redirect, Route, Switch} from "react-router";


class AdminPage extends Component<{ signOut: any, fetchUsers: any, user: any },
    {}> {

    componentDidMount(): void {
        this.props.fetchUsers();
    }

    render() {
        return (
            <div className={'d-flex main-view'}>
                <Route path='/admin/users' component={UsersPage}/>
                <Route path='/admin/logs' component={LogsPageProps}/>
                <Route path='/admin/categories' component={CategoryTable}/>
                <Route exact path="*" render={() => (
                    <Redirect to="/admin/users"/>
                )}/>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    user: userSelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        fetchUsers,
        signOut,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
