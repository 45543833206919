import React, {Component} from 'react';
import './index.scss';

import {signOut, userSelector} from '../../ducks/auth';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {Redirect, Route, Switch} from "react-router";
import HomeTablesPage from "./tables";
import HomeMapPage from "./map";
import axios from "axios";
import {toast} from "react-toastify";


class HomePage extends Component<{ signOut: any, user: any },
    {}> {
    componentDidMount(): void {
        axios.interceptors.response.use(response => {
            return response;
        }, err => {
            return new Promise((resolve, reject) => {
                reject(err);
                if (err.response) {
                    if (err.response.status === 401 && err.response.data.code === 2) {
                        this.props.signOut();
                    }
                }

                if (err.response.status === 400) {
                    let message = '';
                    if (err.response) {
                        if (err.response.data.error && err.response.data.error.errors) {
                            message = err.response.data.error.errors[0].message;
                        } else if (err.response.message) {
                            message = err.response.message;
                        }
                    }
                    if (message) toast.error(message, {
                        position: toast.POSITION.TOP_LEFT
                    });
                }
            });
        });
    }


    render() {
        return (
            <Switch>
                <Route path='/home/map' component={HomeMapPage}/>
                <Route path='/home/tables' component={HomeTablesPage}/>
                <Route exact path="*" render={() => (
                    <Redirect to="/home/map"/>
                )}/>
            </Switch>
        );
    }
}

const mapStateToProps = (state: any) => ({
    user: userSelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        signOut,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
