import React, {Component} from 'react';
import {DropzoneDialog} from 'material-ui-dropzone'
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import {API} from '../../../config'
import './index.scss';

interface MapProps {
    path: string,
    onClick: any,
    onRemove: Function,
}

interface MapState {
    open: boolean,
}


export default class Preivew extends React.PureComponent<MapProps, MapState> {
    static defaultProps = {};

    constructor(p: any) {
        super(p);
        this.state = {
            open: false,
        };
    }

    private onRemove = () => {
        this.props.onRemove();
    }
    private showImages=()=>{

    }

    render() {
        return (
            <div className={'preview'}>
                <img src={`${API}resources/${this.props.path}`} onClick={this.props.onClick}/>
                <IconButton className={'close'} onClick={this.onRemove}>
                    <Icon>close</Icon>
                </IconButton>
            </div>
        );
    }
}


