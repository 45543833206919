import React, {Component} from 'react';
import './index.scss';

import {
    locationPoisSelector,
    locationPolesSelector,
    locationSelector,
    moduleName,
    powerlineSelector
} from "../../../../ducks/map";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import MainTable from "../MainTable";
import {showDialogContent} from "../../../../ducks/dialogs";
import {API} from "../../../../config";
import Title from "../../../../components/title";
import AddPoleDialog from "../../../components/Map/map.container/add.pole";
import {fetchLocationMorePoles, deletePole} from "../../../../ducks/map/poles";

class PolesTable extends MainTable {

    constructor(p: any) {
        super(p);
        const columns: any = [
            {name: 'id', title: 'Id', getCellValue: (row: any) => <Title>{row.id}</Title>},
            {name: 'comment', title: 'Komentarz', getCellValue: (row: any) => <Title>{row.comment}</Title>},
            {
                name: 'layer',
                title: 'layer',
                getCellValue: (row: any) => <Title>{row.layer}</Title>
            },
            {name: 'num_slup', title: 'num_slup', getCellValue: (row: any) => <Title>{row.num_slup}</Title>},
        ];
        const tableColumnExtensions: any = [
            {columnName: 'id', width: 200},
            {columnName: 'comment', width: 200},
            {columnName: 'layer', width: 220},
            {columnName: 'num_slup', width: 220}
        ];
        this.state = {
            ...this.state,
            tableColumnExtensions,
            columns,
            title: 'Poles'
        };
    }

    componentDidMount(): void {
        super.componentDidMount();

    }


    protected URL = (): string => {
        // if (this.props.powerLine) {
        //     return `${API}api/projects/${this.props.project ? this.props.project.id : -1}/powerlines/${this.props.powerLine.id}/parcels`;
        // }
        return `${API}api/projects/${this.props.project ? this.props.project.id : -1}/poles`;
    };


    protected onEditItem = (item: any, onFinishEditItem: Function = () => false) => {
        const {showDialogContent} = this.props;
        showDialogContent(
            <AddPoleDialog
                selectedItem={item}
                onFinishEditItem={() => this.callbacks.push(onFinishEditItem)}
            />
        );
    };


    render() {
        return super._render();
    }
}

const mapStateToProps = (state: any) => ({
    itemsList: state[moduleName].polesList,
    project: locationSelector(state),
    // powerLine: powerlineSelector(state),
    rows: locationPolesSelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showDialogContent,
        onLoadMoreItems: fetchLocationMorePoles,
        onDeleteItem: deletePole,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(PolesTable);
