import {combineReducers} from 'redux';
import authReducer, {moduleName as authModule} from '../ducks/auth';
import mapReducer, {moduleName as mapModule} from '../ducks/map';
import adminReducer, {moduleName as adminModule} from '../ducks/admin';
import dialogReducer, {moduleName as dialogsModule} from '../ducks/dialogs';
import tooltipReducer, {moduleName as tooltipModule} from '../ducks/tooltip';


export default combineReducers({
    [adminModule]: adminReducer,
    [authModule]: authReducer,
    [mapModule]: mapReducer,
    [dialogsModule]: dialogReducer,
    [tooltipModule]: tooltipReducer,
});
