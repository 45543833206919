import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from "redux-devtools-extension";
import reducer from './reducer';
import createSagaMiddleware from 'redux-saga';
import history from '../history';
import {routerMiddleware} from 'react-router-redux';
import rootSaga from './saga';

declare var window: any;
const sagaMiddleware = createSagaMiddleware();
const enhancer = applyMiddleware(sagaMiddleware);

const store = createStore(reducer, composeWithDevTools(enhancer));
window.store = store;
sagaMiddleware.run(rootSaga);

export default store;
