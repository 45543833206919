import React, {Component} from 'react';
import {DropzoneDialog} from 'material-ui-dropzone'
import Button from '@material-ui/core/Button';
import axios from 'axios';
import {toast} from "react-toastify";
import './index.scss';
import {API} from "../../config";

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import {
    Upload,
} from "../../entities";
import Preview from './Preview';
import CircularProgress from '@material-ui/core/CircularProgress';

import ImageGallery from 'react-image-gallery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

interface MapProps {
    files: Array<Upload>,
    onUpdateFile: Function,
    onUpload: Function
}

interface MapState {
    previewImages: any,
    uploaded: number,
    open: boolean,
    files: Array<Upload>
}


export default class UploadFile extends React.PureComponent<MapProps, MapState> {
    static defaultProps = {
        onUpdateFile: () => 0,
        onUpload: () => 0,
        files: []
    };

    constructor(p: any) {
        super(p);
        this.state = {
            previewImages: null,
            uploaded: 0,
            open: false,
            files: [...this.props.files]
        };
    };

    private handleClose() {
        this.setState({
            open: false
        });
    };

    private handleSave = async (files: any) => {
        //Saving files to state for further use and closing Modal.
        const filesList: any = [...this.state.files];
        const filesListNew: any = [];
        let _f = [...filesList, ...files];
        this.setState({
            files: _f,
            open: false
        });
        for (let i = 0; i < files.length; i++) {
            try {
                const _file = files[i];
                _file.__id = i;
                const {data}: any = await this.uploadFile(files[i]);
                filesListNew.push(new Upload(data));
                _f = _f.filter((el) => el.__id !== _file.__id);
                this.setState({
                    files: [..._f, filesListNew[filesListNew.length - 1]],
                });
                _f.push(filesListNew[filesListNew.length - 1]);
            } catch (e) {
                toast.error(e.response ? e.response.data.error || e.response.data.message : e.message || e, {
                    position: toast.POSITION.TOP_LEFT
                });
            }
        }
        this.props.onUpload(filesListNew);
        this.setState({
            // files: [...filesList, ...filesListNew],
            open: false
        });
    };

    private uploadFile(file: any) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);
            axios.post(`${API}api/uploads`, formData, {
                onUploadProgress: progressEvent => {
                    file.uploadStatus = (progressEvent.loaded / progressEvent.total * 100);
                    this.setState({
                        uploaded: file.uploadStatus
                    })
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                resolve(res)
            }).catch(reject)
        });
    };

    private handleOpen() {
        this.setState({
            open: true,
        });
    };

    private onRemove(el: any) {
        this.setState({
            files: this.state.files.filter((els) => el.id !== els.id)
        }, () => {
            this.props.onUpdateFile(this.state.files)
        });
    };

    private onImgPreview = () => {
        this.setState({
            previewImages: this.state.files.filter((el) => el.id).map((el) => ({
                original: `${API}resources/${el.path}`,
                thumbnail: `${API}resources/${el.path}`,
            }))
        })
    };

    private handleClosePreview = () => {
        this.setState({
            previewImages: null
        })
    };

    render() {
        return (
            <div>
                <Button style={{color: '#fff', border: '1px solid #fff', borderRadius: '6px', marginTop: '20px'}} onClick={this.handleOpen.bind(this)}>
                    Dodaj zdjęcie
                </Button>
                {
                    this.state.files.length ? (
                        <div className={'upload-files d-flex a-c'}>
                            {
                                this.state.files.map((el: any) => {
                                    if (el.id) {
                                        return (
                                            <Preview
                                                path={el.path}
                                                key={el.id}
                                                onRemove={() => this.onRemove(el)}
                                                onClick={this.onImgPreview}
                                            />
                                        )
                                    } else {
                                        return <CircularProgress variant="static" value={el.uploadStatus}
                                                                 key={el.name}/>
                                    }
                                })
                            }
                        </div>
                    ) : null
                }

                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    className={'dialog-view'}
                    open={!!this.state.previewImages}
                    onClose={this.handleClosePreview}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle
                        className={'modal-container'}>
                        <div className={'modal-title'}>Preview</div>
                        <IconButton className={'close'} onClick={this.handleClosePreview}>
                            <Icon>close</Icon>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{maxHeight: '80vh'}}>
                        {
                            this.state.previewImages && (
                                <ImageGallery
                                    items={this.state.previewImages}
                                    showIndex
                                    autoPlay
                                    showBullets
                                    slideDuration={400}
                                    slideInterval={5000}
                                />
                            )
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleClosePreview}
                            variant="contained"
                            className={'my-btn btn-primary'}
                        >
                            Anuluj
                        </Button>
                    </DialogActions>
                </Dialog>
                <DropzoneDialog
                    open={this.state.open}
                    onSave={this.handleSave.bind(this)}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                    showPreviews={true}
                    maxFileSize={10000000}
                    onClose={this.handleClose.bind(this)}
                />
            </div>
        );
    }
}


