import Main from './Main';
import {Project} from "./Project";

export class User extends Main {
    email: string;
    firstName: string;
    secondName: string;
    role: number;
    UserProjects: Array<Project>;

    constructor(data: any = {}) {

        super(data);
        this.email = data.email;
        this.firstName = data.firstName;
        this.secondName = data.secondName;
        this.role = data.role;
        this.UserProjects = (data.UserProjects||data.projects||[]).map((el:any)=>new Project(el));
        if (data instanceof User) return data;
    }
}
