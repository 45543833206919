import React, {Component} from 'react';
import './index.scss';
import {
    locationSelector,
    locationsSelector,
    moduleName, powerlineSelector, powerlinesSelector
} from "../../../../ducks/map";
import {bindActionCreators} from "redux";
import {fetchProjectPowerlines} from "../../../../ducks/map/powerlines";
import {showDialogContent} from "../../../../ducks/dialogs";
import {fetchLocationPoi} from "../../../../ducks/map/poi";
import {fetchLocationParcels} from "../../../../ducks/map/parcels";
import {fetchLocationPoles} from "../../../../ducks/map/poles";
import {fetchLocationSegments} from "../../../../ducks/map/segments";
import {fetchLocations, selectLocation} from "../../../../ducks/map/locations";
import {fetchLocationStations} from "../../../../ducks/map/stations";
import {connect} from "react-redux";
import {Button} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import DialogList from "./Dialog.List";

export const TYPES: any = {
    PROJECT: 1,
    POWERLINE: 2
};

interface MapProjectsProps {


    type: number,
    powerline: any,
    project: any,
    powerlines: Array<any>,
    projects: Array<any>,
    showDialogContent: Function,
    fetchLocationSegments: Function,
    fetchProjectPowerlines: Function,
    fetchLocationParcels: Function,
    fetchLocationPoi: Function,
    fetchLocationStations: Function,
    fetchLocationPoles: Function,
    selectLocation: Function,
    fetchLocations: Function
}

class Projects extends Component<MapProjectsProps,
    { search: string }> {
    componentDidMount(): void {
        this.props.fetchLocations();
    }

    state = {
        search: ''
    }
    private onChange = async (proj: any) => {
        this.props.showDialogContent(null);
        const {projects} = this.props;
        const {powerlines} = this.props;
        let list: any = this.props.type === TYPES.PROJECT ? projects : powerlines;

        const item = list.filter((el: any) => el.id === proj.id)[0];
        if (this.props.type === TYPES.PROJECT) {
            this.props.selectLocation(item);
        }

        this.loadProjectData(item);

    };
    private loadProjectData = async (item: any) => {

        if (this.props.type === TYPES.PROJECT) {
            await this.props.fetchLocationStations(item);
            await this.props.fetchLocationPoi(item);
            await this.props.fetchProjectPowerlines(item);
        }
    };

    private onSearch = (e: any) => {
        console.log(e.target.value);
        this.setState({
            search: e.target.value
        })
    }

    private openSelectProjectModal = () => {
        const {projects, project} = this.props;
        const {powerlines} = this.props;
        let list: any = this.props.type === TYPES.PROJECT ? projects : powerlines;
        const title = this.props.type === TYPES.PROJECT ? 'Projekt' : 'Linie';

        if (this.state.search) {
            const search = this.state.search.toLowerCase();
            let keys = Object.keys(list[0]);
            list = list.filter((el: any) => {
                for (let i = 0; i < keys.length; i++) {
                    if (el[keys[i]] && el[keys[i]].toString().toLowerCase().match(search)) {
                        return true;
                    }
                }
                return false;
            })
        }
        this.props.showDialogContent(
            <DialogList
                onChange={this.onChange}
                project={project}
                list={list}
                title={title}
            />
        )
    };

    render() {
        const {project} = this.props;
        const {powerline} = this.props;
        let item: any = this.props.type === TYPES.PROJECT ? project : powerline;
        const {projects} = this.props;
        const {powerlines} = this.props;
        let list: any = this.props.type === TYPES.PROJECT ? projects : powerlines;
        if (list.length === 0) return null;
        const title = this.props.type === TYPES.PROJECT ? 'Projekt: ' : 'Linie: ';

        const exportBtn = (
            <React.Fragment>
                <Button variant="contained" className={'my-btn btn-primary'} onClick={this.openSelectProjectModal}>
                    Wybierz&nbsp;<span style={{textTransform: 'uppercase'}}>{title}</span>
                </Button>
                <Divider style={{marginTop: 20}}/>
            </React.Fragment>
        );
        if (item) {
            return (
                <React.Fragment>
                    <div className={'d-flex a-c j-a project-info'}>
                        <span className={'text-light'}>{title}</span>
                        <span className={'selected-project text-overflow'} title={item.title}>{item.title}</span>
                    </div>
                    {exportBtn}
                </React.Fragment>
            )
        } else {
            return exportBtn
        }

    }
}


const mapStateToProps = (state: any) => {
    return {
        projects: locationsSelector(state),
        powerlines: powerlinesSelector(state),
        powerline: powerlineSelector(state),
        project: locationSelector(state),
        error: state[moduleName].error,
    }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        fetchProjectPowerlines,
        showDialogContent,
        fetchLocationPoi,
        fetchLocationParcels,
        fetchLocationPoles,
        fetchLocationSegments,
        selectLocation,
        fetchLocationStations,
        fetchLocations,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(Projects);
