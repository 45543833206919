import React, {Component} from 'react';

import './index.scss';
import {
    Grid, Table, TableHeaderRow, VirtualTable,
    TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';

import {TableBody, TableCell, TablePagination, withStyles, TableRow, Checkbox, Button} from '@material-ui/core';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/loading'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {
    TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import {
    VirtualTableState,
    DataTypeProvider,
    FilteringState,
    SortingState,
    IntegratedFiltering,
    createRowCache,
} from '@devexpress/dx-react-grid';
import {
    TableColumnVisibility,
    Toolbar,
    ColumnChooser,
    SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import axios from "axios";
import DateEditorBase from "./DatepickerBase";


import DateFormater from "../date.formater";
import {bindActionCreators} from "redux";
import {
    changeControls
} from "../../ducks/map";
import {connect} from "react-redux";
import {searchSelector} from "../../ducks/auth";

const FilterIcon = ({type, ...restProps}: any) => {
    return <TableFilterRow.Icon type={type} {...restProps} />;
};

const VIRTUAL_PAGE_SIZE = 100;
const MAX_ROWS = 100000;
const getRowId = (row: any) => row.id;
const getHiddenColumnsFilteringExtensions = (hiddenColumnNames: any) => hiddenColumnNames
    .map((columnName: any) => ({
        columnName,
        predicate: () => false,
    }));

interface MapProps {
    // hasActions: boolean,
    // hasSelection: boolean,
    // onLoadItems: Function,
    canAddItem: any,
    onAddItem: any,
    onDeleteItem: Function,
    loadedItems: Function,
    onEditItem: Function,
    // onSelectAllClick: Function,
    tableColumnExtensions: Array<any>,
    // rows: Array<any>,
    columns: Array<any>,
    hasDeleteAction: boolean,
    hasEditAction: boolean,
    search: string,
    URL: string,
    title: any,
    // selected: Array<any>
}

interface StateTale {
    tableColumnExtensions: Array<any>,
    refresh: number,
    skip: number,
    totalCount: number,
    totalPageCount: number,
    loading: boolean,
    filteringColumnExtensions: any,
    defaultHiddenColumnNames: Array<string>,
    stringColumns: Array<string>,
    dateColumns: Array<string>,
    filters: Array<any>,
    sorting: Array<any>,
    columns: Array<any>,
    rows: Array<any>
}

class BaseTable extends React.PureComponent<MapProps, StateTale> {
    private cache: any;
    private container: any;

    constructor(props: MapProps) {
        super(props);
        const {hasDeleteAction, hasEditAction} = props;
        const filteringColumnExtensions = getHiddenColumnsFilteringExtensions([]);
        this.state = {
            defaultHiddenColumnNames: [],
            filteringColumnExtensions,
            refresh: Date.now(),
            dateColumns: ['createdAt'],
            stringColumns: this.props.columns.map((el: any) => el),
            columns: [
                ...this.props.columns,
                {
                    name: 'createdAt',
                    title: 'Created At',
                    getCellValue: (row: any) => (<DateFormater>{row.createdAt}</DateFormater>)
                },

            ],
            rows: [],
            tableColumnExtensions: [
                ...this.props.tableColumnExtensions.map((el: any) => {
                    if (typeof el.filteringEnabled === "undefined") el.filteringEnabled = true;
                    if (typeof el.sortingEnabled === "undefined") el.sortingEnabled = true;
                    if (typeof el.width === "undefined") el.width = 200;

                    return el;
                }),
                {columnName: 'createdAt', width: 200, filteringEnabled: true, sortingEnabled: true},
            ],
            skip: 0,
            totalCount: MAX_ROWS,
            totalPageCount: VIRTUAL_PAGE_SIZE,
            loading: true,
            filters: [],
            sorting: [],
        };
        this.cache = createRowCache(VIRTUAL_PAGE_SIZE);
        if (hasDeleteAction || hasEditAction) {
            this.state.columns.push(
                {
                    name: 'actions',
                    title: 'Actions',
                    getCellValue: (row: any) => {
                        return (
                            <div className={'flex'}>
                                {
                                    hasDeleteAction && (
                                        <Tooltip title="Delete">
                                            <IconButton aria-label="Delete" style={{padding: 0}}
                                                        onClick={(e) => this.onFinishDeleteItem(row, e)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }

                                {
                                    hasEditAction && (
                                        <Tooltip title="Edit">
                                            <IconButton aria-label="Edit" style={{padding: 0}}
                                                        onClick={(e) => this.onFinishEditItem(row, e)}>
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }

                            </div>
                        )
                    }
                }
            );
            this.state.tableColumnExtensions.push(
                {columnName: 'actions', width: 125, filteringEnabled: false, sortingEnabled: false}
            );
        }

    }

    static defaultProps = {
        canAddItem: false,
        onAddItem: false
    }

    componentDidMount(): void {
        try {
            const {hasDeleteAction, hasEditAction} = this.props;
            if ((hasDeleteAction || hasEditAction)) {
                setTimeout(() => {
                    try {
                        const _ths = this.container.querySelectorAll('table')[0].querySelector('thead');
                        const els = _ths.children[0].querySelectorAll('th');

                        let resizEAction = els[els.length - 1].children[1];
                        if (!resizEAction) resizEAction = els[els.length - 2].children[1];
                        resizEAction.parentNode.removeChild(resizEAction);
                        const ths = _ths.children[1].querySelectorAll('th');
                        ths.forEach((el: any) => {
                            if (el.querySelector('input[disabled]')) {
                                el.innerHTML = '';
                            }
                        })
                    } catch (e) {
                        console.log(e);
                    }
                }, 300)


            }
            if (this.props.search) this.fullFilter(this.props);
        } catch (e) {
            console.log(e);
        }
    }

    componentWillReceiveProps(nextProps: Readonly<MapProps>, nextContext: any): void {
        if (nextProps.search !== this.props.search) {
            this.fullFilter(nextProps);
        }
    }

    private fullFilter(props: MapProps) {
        const {columns} = this.state;

        const exlude = ['role', 'actions'];
        let filters = columns
            .filter(({name}) => {
                return exlude.indexOf(name) < 0
            })
            .map(({name}) => {
                return {
                    columnName: name,
                    value: props.search
                };
            });

        this.changeFilters(filters, () => this.getRemoteRows(0));
    }

    private onFinishAddItem = () => {
        this.props.onAddItem((updtated: any) => {
            this.changeFilters([]);
            this.setState({refresh: Date.now()});
        });
    }
    private onFinishEditItem = (row: any, e: any) => {
        this.props.onEditItem(row, (updtated: any) => {
            this.changeFilters([]);
            this.setState({refresh: Date.now()});
        });
    }
    private onHiddenColumnNamesChange = (hiddenColumnNames: any) => {
        this.setState({
            filteringColumnExtensions: getHiddenColumnsFilteringExtensions(hiddenColumnNames)
        })
    }
    private onFinishDeleteItem = (row: any, e: any) => {
        this.props.onDeleteItem(row, (updtated: any) => {
            this.changeFilters([]);
            this.setState({refresh: Date.now()});
        });
    }
    private setRows = (skip: any, count: any, totalCount: any = MAX_ROWS) => {

        const {cache} = this;
        const rows = cache.getRows(skip, Math.min(count, totalCount));
        this.setState({
            rows,
            skip,
            totalCount: totalCount < MAX_ROWS ? totalCount : MAX_ROWS,
            totalPageCount: totalCount < VIRTUAL_PAGE_SIZE ? totalCount : VIRTUAL_PAGE_SIZE,
            loading: false,
        }, () => {

        });
    };
    private changeSorting = (sorting: any) => {
        const {cache} = this;
        cache.invalidate();
        this.setState({sorting, rows: [], totalCount: MAX_ROWS, totalPageCount: VIRTUAL_PAGE_SIZE});
    };
    private changeFilters = (filters: any, next: any = () => 0) => {
        const {cache} = this;
        cache.invalidate();
        this.setState({filters, rows: [], totalCount: MAX_ROWS, totalPageCount: VIRTUAL_PAGE_SIZE}, next);
    };

    private getRemoteRows = (skip: number, /*take: number*/) => {
        const take = 100;
        const {totalCount} = this.state;
        const {cache} = this;
        const cached = cache.getRows(skip, take);

        if (cached.length) {
            this.setRows(skip, take, totalCount);
        } else {
            this.setState({loading: true});
            const query = this.buildQueryString(skip, take);
            axios.get(query)
                .then(({data}: any) => {
                    const {rows, count} = data;
                    cache.setRows(skip, rows);
                    this.setRows(skip, take, count);
                    this.props.loadedItems(data);

                })
                .catch(() => this.setState({loading: false}));
        }
    };

    private buildQueryString(skip: number, take: number) {
        const {filters, sorting} = this.state;
        const filterStr = filters
            .map(({columnName, value, operation}) => (
                {
                    columnName,
                    value
                }
            ));
        const sortingConfig = sorting
            .map(({columnName, direction}) => ({
                selector: columnName,
                desc: direction === 'desc',
            }));
        const sortingStr = JSON.stringify(sortingConfig);
        const filterQuery = filterStr ? `&filter=${escape(JSON.stringify(filterStr))}` : '';
        const sortQuery = sortingStr ? `&sort=${escape(`${sortingStr}`)}` : '';
        return `${this.props.URL}?limit=${take}&offset=${skip}${filterQuery}${sortQuery}&isFullSearch=${this.props.search ? 1 : -1}`
    }

    private tableHeight() {
        let height = window.innerHeight - 380;
        const cellHeight = 49;
        const count = Math.round(height / cellHeight);
        height = count * cellHeight;
        return Math.max(height, 308);
    }

    render() {
        const {
            dateColumns, defaultHiddenColumnNames, filteringColumnExtensions,
            rows, columns, skip, totalCount, tableColumnExtensions, totalPageCount, loading, filters, sorting,
        }: any = this.state;
        const {title} = this.props;
        return (
            <Paper style={{position: 'relative'}} ref={(e) => this.container = e} className={'table-view'}>
                <span style={{display: 'none'}}
                      id={this.props.title.toLowerCase()}></span>
                <Grid
                    key={this.state.refresh}
                    rows={rows}
                    columns={columns}
                    getRowId={getRowId}
                >
                    {
                        this.props.canAddItem && (
                            <Typography variant="h6" gutterBottom style={{padding: 20}}>
                                <Button variant="contained" onClick={this.onFinishAddItem}
                                        className={'my-btn btn-primary'}>
                                    Add
                                </Button>
                            </Typography>
                        )
                    }
                    <DataTypeProvider
                        for={dateColumns}
                        editorComponent={DateEditorBase}
                    />
                    <VirtualTableState
                        key={totalPageCount}
                        infiniteScrolling={false}
                        loading={loading}
                        totalRowCount={totalCount}
                        pageSize={totalPageCount}
                        skip={skip}
                        getRows={this.getRemoteRows}
                    />
                    <SortingState
                        columnExtensions={tableColumnExtensions}
                        sorting={sorting}
                        onSortingChange={this.changeSorting}
                    />
                    <FilteringState
                        columnExtensions={tableColumnExtensions}
                        filters={filters}
                        onFiltersChange={this.changeFilters}
                    />
                    <VirtualTable columnExtensions={tableColumnExtensions}
                                  height={this.tableHeight()}/>
                    <TableColumnResizing defaultColumnWidths={tableColumnExtensions}/>
                    <TableHeaderRow showSortingControls/>
                    {
                        !this.props.search && (<TableFilterRow/>)
                    }
                    <TableColumnVisibility
                        defaultHiddenColumnNames={defaultHiddenColumnNames}
                        onHiddenColumnNamesChange={this.onHiddenColumnNamesChange}
                    />
                    {/*<Toolbar/>*/}
                    {/*<ColumnChooser/>*/}
                </Grid>
                {loading && <Loading/>}
            </Paper>
        )
    }
}

const mapStateToProps = (state: any) => ({
    search: searchSelector(state),
});

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        changeControls: changeControls
    }, dispatch)
);
const baseTable = connect(mapStateToProps, mapDispatchToProps)(BaseTable);
export default baseTable;

