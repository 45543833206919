import React, {Component} from 'react';
import './index.scss';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import UploadFile from '../../../../../components/upload';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import {
    Upload,
    Pole,
    Parcel, Segment, Station, Category
} from "../../../../../entities";
import {statuses, parcel_ownership, segment_statuses, segment_operation_type} from "../../../../../utils";
import Loading from "../../../../../components/loading";
import {toast} from "react-toastify";
import {checkError} from "../../../../utils";
import DateFnsUtils from "@date-io/date-fns";

declare var google: any, InfoBox: any, M: any;

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

interface MapProps {
    isAdmin: any,
    itemsList: any,
    position: any,
    selectedItem: any,
    location: any,
    categories: Array<Category>,
    projects: Array<any>,
    tempPosition: Array<any>,
    onFinishEditItem: Function,
    changeControls: Function,
    editItem: Function,
    onDeleteItem: Function,
    onAddItem: Function,
    showDialogContent: Function
}

interface MapState {
    uploads: Array<Upload>,
    categoryId: number,
    canDelete: boolean,
    __pending: boolean
}

export const TYPES = {
    NONE: -1,
    PARCEL: 1,
    POLE: 2,
    STATION: 3,
    POI: 5,
    SEGMENT: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default class MainModalDialog extends Component<MapProps, MapState> {


    protected editTitle: boolean = true;
    protected title: string = '';
    protected type: number = TYPES.NONE;
    static defaultProps: {
        categories: [],
        projects: [],
        itemsList: null,
        position: null,
        tempPosition: [],
        onAddItem: () => false,
        onDeleteItem: () => false,
        onFinishEditItem: () => false,
        changeControls: () => false
    };

    constructor(p: any) {
        super(p);
        this.state = {
            categoryId: -1,
            __pending: false,
            canDelete: false,
            ...p.selectedItem
        }
    }

    componentWillReceiveProps(nextProps: any, nextContext: any): void {
        checkError(nextProps, this.props, () => {
        });
        if (nextProps.itemsList !== this.props.itemsList) {
            this.setState({__pending: false});
            this.handleCancel({});
        }
    }

    private onUploadFile = (fileList: any) => {
        this.setState({
            uploads: [
                ...this.state.uploads,
                ...fileList
            ]
        })
    };
    private onUpdateFile = (fileList: any) => {
        this.setState({
            uploads: [
                ...fileList
            ]
        })
    };
    private onChange = (e: any) => {
        let value = e.target.value;
        if (e.target.getAttribute instanceof Function && e.target.getAttribute('type') === 'number') {
            value = parseFloat(value);
            const min = parseInt(e.target.getAttribute('min'));
            const max = parseInt(e.target.getAttribute('max'));
            if (!isNaN(max) && value > max) {
                value = max;
            }
            if (!isNaN(min) && value < min) {
                value = min;
            }
        }
        const newState: any = {
            [e.target.name]: value
        };
        this.setState(newState);
    };
    private onFieldChange = (key: string) => {
        return (val: any) => {
            const newState: any = {
                [key]: val
            };
            this.setState(newState);
        }
    };

    protected handleOk = async (e: any) => {
        try {
            this.setState({__pending: true});
            const editItem: any = {
                ...this.state,
            };
            if (this.type === TYPES.SEGMENT) {
                if (editItem.operation_type) {
                    editItem.operation_type = editItem.operation_type ? editItem.operation_type.join(", ") : '';
                }
            }
            await this.props.editItem(editItem);
            if (this.props.onFinishEditItem instanceof Function) this.props.onFinishEditItem(editItem);

        } catch (e) {
            toast.error(e && e.response ? e.response.data && (e.response.data.error || e.response.data.message) : e.meesage || e.toString(), {
                position: toast.POSITION.TOP_LEFT
            });
        } finally {
        }
    };

    protected handleCancel = (e: any) => {
        this.props.showDialogContent(null);
    };

    protected deleteItem = async (e: any) => {
        try {
            this.props.onDeleteItem({
                ...this.state,
            });
            this.props.onFinishEditItem();

        } catch (e) {
            console.log(e);
        }
        this.handleCancel(e);
        return false
    };

    private getFieds = () => {
        const fields = [];
        const {state}: any = this;

        const {isAdmin} = this.props;
        if (this.type === TYPES.PARCEL) {
            fields.push(
                {
                    title: 'Status',
                    name: 'status',
                    options: statuses
                },
                {
                    title: 'Ownership',
                    name: 'ownership',
                    options: parcel_ownership
                },
                ...Parcel.edit_keys.map((el: string) => ({
                    title: el,
                    name: el,
                    disabled:!isAdmin
                }))
            );
        } else if (this.type === TYPES.POLE) {
            fields.push(
                ...Pole.edit_keys.map((el: string) => ({
                    title: el,
                    name: el,
                    disabled:!isAdmin
                }))
            );
        } else if (this.type === TYPES.SEGMENT) {
            fields.push(
                {
                    title: 'Status',
                    name: 'status',
                    options: segment_statuses
                },
                {
                    options: [0, 25, 50, 75, 100].map((el: number) => ({
                        value: el,
                        text: el
                    })),
                    name: 'vegetation_status',
                    title: 'Zadrzewienie',
                    disabled:!isAdmin
                },
                {
                    type: 6,
                    step: 1,
                    min: 0,
                    max: 10,
                    name: 'distance_lateral',
                    title: 'Odległość bok'
                },
                {
                    type: 6,
                    step: 1,
                    min: 0,
                    max: 15,
                    name: 'distance_bottom',
                    title: 'Odległość dół'
                },
                ...Segment.edit_keys.map((el: string) => ({
                    title: el,
                    name: el,
                    disabled:!isAdmin
                }))
            );

            if (state.status === segment_statuses[3].value) {
                fields.push(
                    {
                        type: 6,
                        step: 1,
                        min: 1,
                        max: 12,
                        name: 'shutdown_time',
                        title: 'Czas wyłączenia'
                    },
                    {
                        name: 'track',
                        title: 'Tor',
                        options: [1, 2].map((el: number) => ({
                            value: el,
                            text: el
                        }))
                    },
                );
            }
            if ([
                segment_statuses[1].value,
                segment_statuses[2].value,
                segment_statuses[3].value,
                segment_statuses[4].value,
                segment_statuses[6].value,
            ].indexOf(state.status) > -1
            ) {
                fields.push(
                    {
                        type: 3,
                        name: 'operation_type',
                        title: 'Rodzaj zabiegu',
                        options: segment_operation_type
                    },
                    {
                        type: 6,
                        step: 1,
                        min: 1,
                        max: 12,
                        name: 'time_of_operation',
                        title: 'time of operation'
                    },
                );
            }

            if ([
                segment_statuses[4].value
            ].indexOf(state.status) > -1
            ) {
                fields.push(
                    {
                        name: 'time_for_next_entry',
                        title: 'Czas następnego wejścia'
                    }
                );
            }
            if ([
                segment_statuses[6].value
            ].indexOf(state.status) > -1
            ) {
                fields.push(
                    {
                        name: 'parcel_number_for_permit',
                        title: 'Numer działki z pozwoleniem'
                    }
                );
            }
        } else if (this.type === TYPES.STATION) {
            fields.push(
                ...Station.edit_keys.map((el: string) => ({
                    title: el,
                    name: el,
                    disabled:!isAdmin
                }))
            );
        } else if (this.type === TYPES.POI) {
            fields.push(
                {
                    title: 'Projekt',
                    name: 'projectId',
                    options: this.props.projects.map((el: any) => ({
                        text: el.title,
                        value: el.id
                    })),
                    disabled:!isAdmin
                },
                {
                    title: 'Kategoria',
                    name: 'categoryId',
                    required: true,
                    options: this.props.categories.map((el: any) => ({
                        text: el.title,
                        value: el.id
                    })),
                    disabled:!isAdmin
                },
            );
        }
        return fields;
    };

    private valuetext(value: number) {
        return `${value}C`;
    };

    protected _render() {
        const state: any = this.state;
        const {title, comment}: any = this.state;
        const {selectedItem}: any = this.props;
        const fields = this.getFieds();

        return (
            <React.Fragment>
                <DialogTitle
                    className={'modal-container'}
                    id="max-width-dialog-title">
                    <div className={'modal-title'}>{selectedItem.id ? `Edytuj` : 'Utwórz'} {this.title} {selectedItem.id ? `(${selectedItem.id})` : ''}</div>
                    <IconButton className={'close'} onClick={this.handleCancel}>
                        <Icon style={{color: '#fff'}}>close</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form autoComplete="off" className={'d-flex f-col'}>
                        {
                            fields.map((el: any) => {
                                if (el.type === 2) {
                                    return (
                                        <FormControl key={el.name}>
                                            <Typography id="discrete-slider-always" gutterBottom>
                                                {el.title}
                                            </Typography>
                                            <Slider
                                                getAriaValueText={this.valuetext}
                                                onChange={(e, value) => this.onChange({target: {name: el.name, value}})}
                                                defaultValue={state[el.name]}
                                                aria-labelledby="discrete-slider-always"
                                                step={el.step}
                                                marks={el.marks || []}
                                                min={el.min}
                                                max={el.max}
                                                valueLabelDisplay="on"
                                            />
                                        </FormControl>
                                    )
                                } else if (el.type === 3) {
                                    return (
                                        <FormControl key={el.name}>
                                            <Typography id="discrete-slider" style={{color: '#fff'}} gutterBottom>
                                                {el.title}
                                            </Typography>
                                            <Select
                                                multiple
                                                value={(typeof state[el.name] === 'string' && state[el.name].length) ? state[el.name].split(', ') : state[el.name] || []}
                                                onChange={this.onChange}
                                                input={<Input id="select-multiple-checkbox"/>}
                                                renderValue={(selected) => {
                                                    if(selected) {
                                                        if(typeof selected === 'string') {
                                                            return selected;
                                                        }
                                                        return (selected as string[]).join(', ');
                                                    }
                                                }}
                                                MenuProps={MenuProps}
                                                inputProps={{
                                                    name: el.name,
                                                    id: el.name,
                                                }}
                                            >
                                                {
                                                    el.options.map((opt: any) => {
                                                       return (
                                                           <MenuItem style={{color: '#fff'}} key={opt.value} value={opt.value}>
                                                               <Checkbox style={{color: '#fff'}} checked={state[el.name] ? state[el.name].indexOf(opt.value) > -1 : false}/>
                                                               <ListItemText primary={opt.text}/>
                                                           </MenuItem>
                                                       )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    )
                                } else if (el.type === 4) {
                                    return (
                                        <FormControl key={el.name}>
                                            <Typography id="discrete-slider" gutterBottom>
                                                {el.title}
                                            </Typography>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    variant="inline"
                                                    openTo="year"
                                                    views={["year", "month"]}
                                                   // label="Year and Month"
                                                   // helperText={el.title}
                                                    value={state[el.name]}
                                                   // name={el.name}
                                                    onChange={this.onFieldChange(el.name)}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                    )
                                } else if (el.type === 5) {
                                    return (
                                        <FormControl key={el.name}>
                                            <TextField
                                                multiline={true}
                                                rows={5}
                                                label={el.title}
                                                value={state[el.name]}
                                                name={el.name}
                                                onChange={this.onChange}
                                                margin="normal"
                                            />
                                        </FormControl>
                                    )
                                } else if (el.type === 6) {
                                    return (
                                        <FormControl key={el.name}>
                                            <TextField
                                                inputProps={{
                                                    step: 0.01,
                                                    min: el.min,
                                                    max: el.max
                                                }}
                                                type={'number'}
                                                label={el.title}
                                                value={state[el.name]}
                                                name={el.name}
                                                onChange={this.onChange}
                                                margin="normal"
                                            />
                                        </FormControl>
                                    )
                                } else if (el.options) {
                                    return (
                                        <FormControl key={el.name}>
                                            <InputLabel htmlFor="age-simple">{el.title}</InputLabel>
                                            <Select
                                                style={{minWidth: 120, marginBottom: 15}}
                                                value={state[el.name]}
                                                onChange={this.onChange}
                                                disabled={el.disabled}
                                                inputProps={{
                                                    name: el.name,
                                                    id: el.name,
                                                }}
                                            >
                                                {
                                                    el.options.map((el: any) => (
                                                            <MenuItem style={{color: '#fff'}} key={el.value} value={el.value}>{el.text}</MenuItem>
                                                        )
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    )
                                } else {
                                    return (
                                        <FormControl key={el.name}>
                                            <TextField
                                                label={el.title}
                                                value={state[el.name]}
                                                name={el.name}
                                                onChange={this.onChange}
                                                disabled={el.disabled}
                                                margin="normal"
                                            />
                                        </FormControl>
                                    )
                                }
                            })
                        }


                        {
                            this.editTitle ? (
                                <React.Fragment>
                                    <FormControl>
                                        <TextField
                                            label="Nazwa"
                                            value={title}
                                            name={'title'}
                                            onChange={this.onChange}
                                            margin="normal"
                                        />
                                    </FormControl>
                                </React.Fragment>
                            ) : null
                        }

                        <React.Fragment>
                            <FormControl>
                                <TextField
                                    multiline={true}
                                    rows={5}
                                    label="Komentarz"
                                    value={comment}
                                    name={'comment'}
                                    onChange={this.onChange}
                                    margin="normal"
                                />
                            </FormControl>
                        </React.Fragment>

                        <UploadFile
                            files={selectedItem.uploads}
                            onUpload={this.onUploadFile}
                            onUpdateFile={this.onUpdateFile}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} className={'relative'}>
                        Anuluj
                    </Button>
                    <Button variant="contained" onClick={this.handleOk} className={'my-btn btn-primary'}>
                        Zapisz
                        {this.state.__pending && <Loading/>}
                    </Button>
                    {
                        this.state.canDelete && selectedItem.id && (
                            <Button onClick={this.deleteItem}>
                                Delete
                            </Button>
                        )
                    }
                </DialogActions>
            </React.Fragment>
        );
    }
}

