import React from 'react';
import {exportToCsv} from "../../../../../utils";
import {isSuperAdminSelector, signOut, userSelector} from "../../../../../ducks/auth";
import {
    locationParcelsSelector,
    locationPoisSelector,
    locationPolesSelector,
    locationSegmentsSelector, locationStationsSelector, moduleName
} from "../../../../../ducks/map";
import {showAlert} from '../../../../../ducks/dialogs';
import {logSelector, usersSelector} from "../../../../../ducks/admin";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import {Button, Icon} from '@material-ui/core';

function ExportData(props: any) {
    const {

        isSuperAdmin,
    }: any = props;

    function createCSVData(list: Array<any>, name: string) {
        let filename = '';
        let csvData: any = [];
        const exclude = ['points'];
        if (list.length) {
            filename = `${name}.csv`;
            const keys = Object.keys(list[0]).filter((el) => exclude.indexOf(el) < 0);
            csvData = [
                keys,
                ...list.map((el: any) => {
                    return [
                        keys.map((key: any) => {
                            return el[key] ? el[key] + '' : ''
                        })
                    ]
                })
            ];
        }
        return {
            filename,
            csvData
        }
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function exportTable(dataType: string) {
        const {filename, csvData} = createCSVData(props[dataType], dataType);

        if (props[dataType] == null || !props[dataType].length) {
            return props.showAlert('To export data please select project and powerline');
        }

        exportToCsv(filename, {data: props[dataType]});
        handleClose();
    }

    const canExportLogs = location.href.match('admin/logs');
    const canExportUsers = location.href.match('admin/users');
    const canExportEntities = location.href.match('home/map') || location.href.match('home/tables');
    return (

        <div>
            <Button variant="contained" className={'my-btn btn-primary'} onClick={handleClick}>
                Eksportuj dane
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    canExportEntities ? (
                        <MenuList>
                            <MenuItem onClick={() => exportTable('parcels')}>Parcels to CSV</MenuItem>
                            <MenuItem onClick={() => exportTable('poles')}>Poles to CSV</MenuItem>
                            <MenuItem onClick={() => exportTable('segments')}>Segments to CSV</MenuItem>
                            <MenuItem onClick={() => exportTable('pois')}>Pois to CSV</MenuItem>
                            <MenuItem onClick={() => exportTable('stations')}>Stations to CSV</MenuItem>
                        </MenuList>
                    ) : (null)
                }
                {
                    isSuperAdmin && canExportUsers && (
                        <MenuItem onClick={() => exportTable('users')}>Users to CSV</MenuItem>)
                }
                {
                    isSuperAdmin && canExportLogs && (
                        <MenuItem onClick={() => exportTable('logs')}>Logs to CSV</MenuItem>)
                }
            </Menu>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    polesList: state[moduleName].polesList,
    parcelList: state[moduleName].parcelList,
    stationList: state[moduleName].stationList,
    segmentList: state[moduleName].segmentList,
    poiList: state[moduleName].poiList,

    user: userSelector(state),
    isSuperAdmin: isSuperAdminSelector(state),
    segments: locationSegmentsSelector(state),
    parcels: locationParcelsSelector(state),
    pois: locationPoisSelector(state),
    poles: locationPolesSelector(state),
    stations: locationStationsSelector(state),
    logs: logSelector(state),
    users: usersSelector(state),
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        signOut,
        showAlert
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(ExportData);
