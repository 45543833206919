import React, {Component} from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {showDialogContent} from "../../../ducks/dialogs";
import {API} from "../../../config";
import Title from "../../../components/title";
import {deleteUser, editUser, fetchMoreUsers} from "../../../ducks/admin/users";
import {moduleName} from "../../../ducks/admin/config";
import MainTable from "../../home/tables/MainTable";
import {BootstrapTooltip} from "../../../components/tooltip/material.tooltips";
import AddUserDialog from "./add.edit.users";
import {locationPoisSelector, locationSelector} from "../../../ducks/map";
import {User} from "../../../entities";
import {errorSelector} from "../../../ducks/admin";
import {USER_ROLE} from "../../../ducks/auth";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

class UserTable extends MainTable {

    constructor(p: any) {
        super(p);
        const columns: any = [
            {name: 'email', title: 'Email', getCellValue: (row: any) => <Title>{row.email}</Title>},
            {
                name: 'firstName',
                title: 'FirstName',
                getCellValue: (row: any) => <Title>{row.firstName}</Title>
            },
            {name: 'secondName', title: 'secondName', getCellValue: (row: any) => <Title>{row.secondName}</Title>},
            {
                name: 'role', title: 'Role', getCellValue: (item: any) => {
                    if (item.role === USER_ROLE.SUPER_USER) {
                        return null;
                    }
                    return (
                        <FormControl>
                            <RadioGroup
                                className={'d-flex f-row radio-group'}
                                aria-label="Gender"
                                value={item.role.toString()}
                                onChange={(role: any) => this.handleChange(item, role)}
                            >
                                {/*<FormControlLabel value={USER_ROLE.SUPER_USER} control={<Radio/>} label="Super Admin"/>*/}
                                <FormControlLabel value={USER_ROLE.ADMIN.toString()} control={<Radio/>} label="Admin"/>
                                <FormControlLabel value={USER_ROLE.USER.toString()} control={<Radio/>} label="User"/>
                            </RadioGroup>
                        </FormControl>
                    )
                }
            },
            {
                name: 'projects', title: 'Projects', getCellValue: (item: any) => {
                    // debugger
                    const projects = (
                        <div className={'list-view'}>
                            {
                                item.UserProjects.map((el: any) => {
                                    return (
                                        <Chip
                                            style={{margin: 5}}
                                            key={el.id}
                                            label={<Title>{el.title}</Title>}
                                        />
                                    )
                                })
                            }
                        </div>
                    );
                    const maxView = 3;
                    const viewProjects = (
                        <div className={'d-flex a-c f-col'}>
                            {
                                item.UserProjects
                                    .filter((el: any, index: number) => index < maxView)
                                    .map((el: any) => {
                                        return (
                                            <Chip
                                                style={{margin: 5}}
                                                key={el.id}
                                                label={<Title>{el.title}</Title>}
                                            />
                                        )
                                    })
                            }
                            {
                                item.UserProjects.length > maxView ? <span>...</span> : null
                            }
                        </div>
                    );
                    return (
                        <BootstrapTooltip title={projects}>
                            <span>Assigned({item.UserProjects.length})</span>
                        </BootstrapTooltip>
                    )


                }
            },
        ];
        const tableColumnExtensions: any = [
            {columnName: 'email', width: 200},
            {columnName: 'title', width: 200},
            {columnName: 'role', width: 200, filteringEnabled: false, sortingEnabled: false},
            {columnName: 'firstName', width: 220},
            {columnName: 'projects', width: 220, filteringEnabled: false, sortingEnabled: false},
            {columnName: 'secondName', width: 220}
        ];
        const title: any = 'Users';
        this.state = {
            ...this.state,
            HAVE_NO_PROJECT: true,
            canAddItem: true,
            tableColumnExtensions,
            columns,
            title
        };
    }


    private handleChange = (item: User, ev: any) => {
        this.props.onEditItem({...item, role: parseInt(ev.target.value)});
        this.callbacks.push(() => 0)
    };

    protected onAddItem = (onFinishEditItem: Function = () => false) => {
        const {showDialogContent} = this.props;
        showDialogContent(
            <AddUserDialog
                selectedItem={new User()}
                onFinishEditItem={() => this.callbacks.push(onFinishEditItem)}
            />
        );
    };

    componentDidMount(): void {
        super.componentDidMount();

    }


    protected URL = (): string => {
        return `${API}api/admin/user`;
    };


    protected onEditItem = (item: any, onFinishEditItem: Function = () => false) => {
        const {showDialogContent} = this.props;
        showDialogContent(
            <AddUserDialog
                selectedItem={item}
                onFinishEditItem={() => this.callbacks.push(onFinishEditItem)}
            />
        );
    };


    render() {
        return super._render();
    }
}

const mapStateToProps = (state: any) => ({
    itemsList: state[moduleName].userList,
    project: locationSelector(state),
    error: errorSelector(state),
    rows: locationPoisSelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showDialogContent,
        onLoadMoreItems: fetchMoreUsers,
        onDeleteItem: deleteUser,
        onEditItem: editUser
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
