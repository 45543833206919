import React, {Component} from 'react';
import './index.scss';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import Button from '@material-ui/core/Button';
import {showAlert, alertTextSelector} from "../../../../ducks/dialogs";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import DialogTitle from '@material-ui/core/DialogTitle';
import {changeControls} from "../../../../ducks/map";
import {Parcel, Pole, Segment, Station} from "../../../../entities";


interface MapContainerProps {
    changeControls: Function,
    showAlert: any,
    alertText: string
}

class AlertDialog extends Component<MapContainerProps,  // { showAlert: any, alertText: string },
    {}> {

    private onAllowToaddPoi = () => {
        this.props.changeControls({
            name: 'allowAddPoi',
            value: false
        });
        this.props.changeControls({
            name: 'showPois',
            value: true
        });
    };

    private handleClose = () => {
        this.props.showAlert(false);
        this.onAllowToaddPoi();
    };

    render() {
        return (
            <Dialog
                open={!!this.props.alertText}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    className={'modal-container'}>
                    <div className={'modal-title'}>Warning</div>
                    <IconButton className={'close'} onClick={this.handleClose}>
                        <Icon>close</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.alertText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state: any) => ({
    alertText: alertTextSelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showAlert,
        changeControls: changeControls,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);

