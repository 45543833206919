export const SETTINGS = {
    ACTIVE: {
        POLE: {
            strokeColor: '#3cdc95',
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: '#3cdc95',
            fillOpacity: 1,
        },
        POLYGON: {
            strokeWeight: 2,
            strokeColor: '#28ddb8',
            fillOpacity: 0.0,
            fillColor: '#28ddb8',
            strokeOpacity: 0.65,
        },
        POLYGON_STATION: {
            strokeWeight: 2,
            strokeColor: '#ff00ff',
            fillOpacity: 0.35,
            fillColor: '#FF00ff',
            strokeOpacity: 0.8,


        },
        LINE: {
            strokeColor: '#00989a',
            strokeOpacity: 0.5,
            strokeWeight: 5
        }
    },
    IN_ACTIVE: {
        POLYGON: {
            strokeWeight: 2,
            strokeColor: '#ff6f54',
            strokeOpacity: 0.6,
            fillOpacity: 0.2,
            fillColor: '#ff6f54',
        },
        LINE: {
            strokeColor: '#abb0c7',
            strokeOpacity: 0.6,
            strokeWeight: 2
        }
    },
}
