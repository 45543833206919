import React, {Component} from 'react';
import './index.scss';

import BaseTable from '../../../../components/base.table';
import DeleteItemDialog from '../../../../components/DeleteItemDialog';
import {Grid} from "@devexpress/dx-react-grid-material-ui";
import Typography from '@material-ui/core/Typography';
import {onLoadMoreItems} from "../../../../ducks/map/parcels";
import {checkError} from "../../../utils";

interface MainTableProps {
    itemsList: any,
    powerLine: any,
    project: any,
    rows: Array<any>,
    onEditItem: Function,
    onLoadMoreItems: Function,
    showDialogContent: Function,
    onDeleteItem: Function
}

interface MainTableState {
    tableColumnExtensions: Array<any>,
    columns: Array<any>,
    HAVE_NO_PROJECT: boolean,
    refresh: number,
    canAddItem: boolean,
    hasEditAction: boolean,
    hasDeleteAction: boolean,
    title: any,
}

export default class MainTable extends Component<MainTableProps, MainTableState> {

    protected callbacks: Array<Function> = [];
    state = {
        refresh: Date.now(),
        hasEditAction: true,
        hasDeleteAction: true,
        canAddItem: false,
        title: '',
        tableColumnExtensions: [],
        HAVE_NO_PROJECT: false,
        columns: [],
    };

    static defaultProps = {
        powerLine: null,
        project: null,
        onEditItem: () => 0
    };

    componentDidMount(): void {
    }

    componentWillReceiveProps(nextProps: any, nextContext: any): void {
        checkError(nextProps, this.props, () => {
        });
        if (nextProps.itemsList !== this.props.itemsList) {
            if (this.callbacks.length) {
                this.setState({
                    refresh: Date.now()
                })
            }
            while (this.callbacks.length) {
                const func = this.callbacks.shift();
            }
        }
    }

    protected actionsColumns = () => {
        return [
            {
                name: 'title',
                title: 'Actions'
            },
        ]
    };
    protected onDeleteItem = (item: any, onFinishEditItem: Function = () => false) => {
        const {showDialogContent} = this.props;
        const {title}: any = this.state;
        showDialogContent(
            <DeleteItemDialog
                title={`${title}(${item.id})`}
                onCancel={() => showDialogContent(null)}
                onAccept={() => {
                    this.props.onDeleteItem(item);
                    showDialogContent(null);
                    this.callbacks.push(onFinishEditItem);
                }}
            />
        );
    };
    protected onAddItem = (item: any) => {

    }
    protected onEditItem = (item: any) => {

    };

    protected URL = (): string => {
        return '';
    }
    protected onLoadMoreItems = (items: any): string => {
        return '';
    }

    _render() {
        const {title}: any = this.state;
        if (!this.props.project && !this.state.HAVE_NO_PROJECT) {
            return (
                <Typography style={{color: '#fff'}} variant="h6" gutterBottom>
                    Proszę wybrać projekt
                </Typography>
            )
        }
        const URL = this.URL();
        const key = ((this.props.project ? this.props.project.id : -1) + this.state.refresh) + URL;
        return (
            <BaseTable
                key={key}
                canAddItem={this.state.canAddItem}
                hasEditAction={this.state.hasEditAction}
                hasDeleteAction={this.state.hasDeleteAction}
                onAddItem={this.onAddItem}
                URL={URL}
                columns={this.state.columns}
                loadedItems={this.props.onLoadMoreItems}
                tableColumnExtensions={this.state.tableColumnExtensions}
                onDeleteItem={this.onDeleteItem}
                onEditItem={this.onEditItem}
                title={title}
            />
        );
    }
}

