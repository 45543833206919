import React, {Component} from 'react';
import './index.scss';
import {
    errorSelector,
    lastGeoPostionsSelector,
    locationSelector,
    changeControls,
    moduleName
} from "../../../../../ducks/map";
import {addPoleParcel, editParcel} from "../../../../../ducks/map/parcels";
import {showDialogContent} from "../../../../../ducks/dialogs";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {isSuperADMINAdminSelector} from "../../../../../ducks/auth";
import MainModalDialog, {TYPES} from "../main.modal";


class AddParcelDialog extends MainModalDialog {

    constructor(p: any) {
        super(p);
        this.title = 'Parcel';
        this.type = TYPES.PARCEL;
        this.editTitle = false;
    }

    render() {
        return super._render();
    }
}

const mapStateToProps = (state: any) => ({
    itemsList: state[moduleName].parcelList,
    error: errorSelector(state),
    location: locationSelector(state),
    isAdmin: isSuperADMINAdminSelector(state),
    tempPosition: lastGeoPostionsSelector(state)
});

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        changeControls: changeControls,
        showDialogContent,
        addItem: addPoleParcel,
        editItem: editParcel,
    }, dispatch)
);
const edit = connect(mapStateToProps, mapDispatchToProps)(AddParcelDialog);
export default edit;
