import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import Typography from '@material-ui/core/Typography';
import {signIn, moduleName,changeSettings} from '../../../ducks/auth';
import './login.scss';
import Loading from "../../../components/loading";
import Logo from "../../../assets/img/logo_BKW1@2x.png";

import FormControl from '@material-ui/core/FormControl';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';

class Login extends Component<{ changeSettings:Function,signIn: any, authError: boolean, loading: boolean },
    {}> {
    state = {
        error: false,
        email: '',
        password: '',
    };

    componentWillUnmount(): void {
        this.props.changeSettings({})
    }

    onInput = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value,
            error: false,
        });
    };
    onSubmitForm = async (e: any) => {
        const form: any = this.refs.form;
        form.submit();
    };
    onSubmit = async (e: any) => {
        // e.preventDefault();
        // e.stopPropagation();
        const form: any = this.refs.form;
        form.isFormValid().then(async (valid: any) => {
            if (valid) {
                try {
                    await this.props.signIn(this.state);
                } catch (e) {
                    this.setState({
                        error: 'Error! Either user or password are wrong. Please try again',
                    });
                }
            } else {
                this.setState({
                    disabled: true,
                });
            }
        })
    };

    render() {
        const {authError} = this.props;
        return (
            <div className={'first-page d-flex a-c j-c'}>
                <div className={'main-page-view text-center'}>
                    <img className={`logo `} src={Logo}/>

                    <Typography className={'title'} variant="h6" noWrap>
                        Witaj
                    </Typography>

                    <ValidatorForm
                        ref="form"
                        onSubmit={this.onSubmit}
                        onError={(errors: any) => console.log(errors)}
                        className={'d-flex f-col login-form-body'}
                    >
                        <FormControl>
                            <TextValidator
                                autoComplete={'off'}
                                id={'email'}
                                type={'email'}
                                label={'Email'}
                                placeholder={'Wpisz email'}
                                value={this.state.email}
                                name={'email'}
                                onChange={this.onInput}
                                margin="normal"
                                validators={['required', 'isEmail']}
                                errorMessages={['this field is required', 'email is not valid']}
                            />
                        </FormControl>
                        <FormControl>
                            <TextValidator
                                autoComplete={'off'}
                                id={'password'}
                                type={'password'}
                                label={'Hasło'}
                                placeholder={'Wpisz hasło'}
                                value={this.state.password}
                                name={'password'}
                                onChange={this.onInput}
                                margin="normal"
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </FormControl>
                    </ValidatorForm>
                    <div className={'d-flex f-col'}>
                        <div className={'d-flex j-end'}>
                            <Link to="/forgot-psw" className={'link'}>Zapomniałeś hasła?</Link>
                        </div>
                        <div className={'d-flex j-center'}>
                            <Button
                                variant="contained"
                                className={'my-btn btn-primary'}
                                onClick={this.onSubmitForm}
                                style={{width: 160, textTransform: "uppercase"}}
                            >
                                Zaloguj
                                {this.props.loading && <Loading/>}
                            </Button>
                        </div>
                    </div>
                    {
                        authError && (<p style={{color: 'red'}} className={'error-message'}>
                            Error! Either user or password are wrong. Please try again
                        </p>)
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    authError: state[moduleName].error,
    loading: state[moduleName].loading,
});

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        changeSettings,
        signIn,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(Login);
