import {API, appName} from '../config';
import {Record} from 'immutable';
import {all, cps, call, put, take, takeEvery} from 'redux-saga/effects';
import axios from 'axios';
import {createSelector} from 'reselect';


export const ReducerRecord: any = Record({
    tooltipInfo: false,

    error: null,
});

export const moduleName = 'tooltip';

export const SHOW_TOOLTIP = `${appName}/${moduleName}/SHOW_TOOLTIP`;
export const SHOW_TOOLTIP_REQUEST = `${appName}/${moduleName}/SHOW_TOOLTIP_REQUEST`;


export default function reducer(state = new ReducerRecord(), action: any) {
    const {type, payload, error} = action;

    switch (type) {
        case SHOW_TOOLTIP:
            return state
                .set('tooltipInfo', payload)
                .set('error', null);

        default:
            return state;
    }
}


export const stateSelector = (state: any) => state[moduleName];
export const errorSelector = createSelector(stateSelector, state => state.error);
export const toolltipInfo = createSelector(stateSelector, state => state.tooltipInfo);

export class TooltipInfo {
    left: number = 0;
    top: number = 0;
    content: string = '';

    constructor(left: number, top: number, content: string) {
        this.left = left;
        this.top = top;
        this.content = content;
    }
}

export function showMarkerMapTooltip(info: any) {
    return {
        type: SHOW_TOOLTIP,
        payload: info
    };
}


const showMarkerMapTooltipSaga = function* (action: any) {
    try {
        yield put({
            type: SHOW_TOOLTIP_REQUEST,
            payload: action.payload
        });

    } catch (error) {

    }
};
export const saga = function* () {
    yield all([
        takeEvery(SHOW_TOOLTIP, showMarkerMapTooltipSaga),
    ]);
};
