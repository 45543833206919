import React, {Component} from 'react';
import './index.scss';

import {isSuperAdminSelector, isVerifiedSelector, signOut, userSelector} from '../../ducks/auth';
import {fetchCategories} from '../../ducks/admin/categories';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import Layout1 from "../components/Layouts/Layout1";
import {Redirect, Route, Switch} from "react-router";
import AdminPage from "../admin";
import HomePage from "../home";
import ChangepswPage from "../auth/reset-psw";
import MapTooltip from "../../components/tooltip";

interface ItemProps {
    fetchCategories: Function,
    signOut: any,
    user: any,
    isSuperAdmin: boolean,
    isVerified: boolean
}

class MainPage extends Component<ItemProps,
    {}> {

    componentDidMount(): void {
        this.props.fetchCategories();
    }

    render() {
        if (this.props.isVerified) {
            return (
                <div>
                    <MapTooltip/>
                    <Layout1>
                        <div className={'d-flex'}>
                            <Switch>
                                <Route path='/home' component={HomePage}/>
                                {
                                    this.props.isSuperAdmin && (
                                        <Route path='/admin' component={AdminPage}/>
                                    )
                                }
                                <Route exact path="*" render={() => (
                                    <Redirect to="/home"/>
                                )}/>
                            </Switch>
                        </div>
                    </Layout1>
                </div>

            );
        } else {
            return (
                <Switch>
                    <Route path='/change-psw' component={ChangepswPage}/>
                    <Route exact path="*" render={() => (
                        <Redirect to="/change-psw"/>
                    )}/>
                </Switch>
            )
        }
    }
}

const mapStateToProps = (state: any) => ({
    user: userSelector(state),
    isSuperAdmin: isSuperAdminSelector(state),
    isVerified: isVerifiedSelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        signOut,
        fetchCategories
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
