import React, {Component} from 'react';

import store from './redux'
import {Provider} from 'react-redux'

import AppRoutes from './routes';
import AlertDialog from './pages/components/Dialogs/Alert';
import DialogForm from './pages/components/Dialogs/DialogForm';

import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer, toast} from 'react-toastify';


export default class App extends Component {
    render() {
        return (
            <div>
                <Provider store={store}>
                    <AppRoutes/>
                    <AlertDialog/>
                    <DialogForm/>
                    <ToastContainer/>
                </Provider>
            </div>
        );
    }
}
