import React, {Component} from 'react';
import './index.scss';
import {addUser, editUser} from "../../../../ducks/admin/users";
import {errorSelector, moduleName} from "../../../../ducks/admin";
import {showDialogContent} from "../../../../ducks/dialogs";
import {bindActionCreators} from "redux";
import {connect, Provider} from "react-redux";

import {checkError} from '../../../utils';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import {
    User
} from "../../../../entities";
import {locationsSelector} from "../../../../ducks/map";
import {fetchLocations} from "../../../../ducks/map/locations";
import Loading from "../../../../components/loading";
import {TextField} from "@material-ui/core";

const ValidF: any = ValidatorForm;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface UserPageProps {
    userItemEdited: any,
    userItemAdded: any,
    error: any,
    projects: Array<any>,
    selectedItem: User,
    addItem: Function,
    onFinishEditItem: Function,
    editItem: Function,
    showDialogContent: Function,
    fetchLocations: Function,
}

class AddEditUserDialog extends Component<UserPageProps,
    {
        password: string,
        pending: boolean,
        repeatPassword: string,
        id: any,
        disabled: boolean
    }> {

    constructor(p: any) {
        super(p);
        this.state = {
            email: '',
            firstName: '',
            secondName: '',
            repeatPassword: '',
            UserProjects: [],
            id: '',
            ...p.selectedItem,
            password: '',
            pending: false,
            disabled: false
        };
    };

    static defaultProps = {
        onFinishEditItem: () => 1
    };

    componentDidMount(): void {
        ValidF.addValidationRule('isPasswordMatch', (value: string) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
        this.props.fetchLocations();
    };

    componentWillUnmount() {
        ValidF.removeValidationRule('isPasswordMatch');
    };

    componentWillReceiveProps(nextProps: Readonly<UserPageProps>, nextContext: any): void {
        checkError(nextProps, this.props, () => {
            this.setState({
                pending: false,
                disabled: false,
            });
        });
        if (nextProps.userItemAdded !== this.props.userItemAdded || nextProps.userItemEdited !== this.props.userItemEdited) {
            this.handleCancel();
            this.setState({
                pending: false,
                disabled: false,
            });
        }
    };

    private onChange = (e: any) => {
        if (e.target.name === 'UserProjects') {
            e.target.value = this.props.projects.filter((el) => e.target.value.indexOf(el.id) > -1);
        }
        const newState: any = {
            disabled: false,
            [e.target.name]: e.target.value
        };
        this.setState(newState);
    };

    private handleOk = async (e: any) => {
        const form: any = this.refs.form;
        form.submit();
    };

    private handleCancel = () => {
        this.props.showDialogContent(null);
    };

    private handleSubmit = (e: any) => {
        const form: any = this.refs.form;
        form.isFormValid().then(async (valid: any) => {
            if (valid) {
                this.setState({
                    pending: true,
                });
                if (this.state.id) {
                    await this.props.editItem({
                        ...this.state,
                    });
                } else {
                    await this.props.addItem({
                        ...this.state,
                    });
                }

                this.props.onFinishEditItem();
            } else {
                this.setState({
                    disabled: true,
                });
            }
        })
    };

    render() {
        const {email, firstName, secondName, UserProjects, password, repeatPassword, id, disabled, pending}: any = this.state;

        const projects = UserProjects.map((el: any) => el.id);
        return (
            <React.Fragment>
                <DialogTitle
                    className={'modal-container'}>
                    <div className={'modal-title'}>{id ? 'Edit' : 'Add'} user</div>
                    <IconButton className={'close'} onClick={this.handleCancel}>
                        <Icon>close</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent>

                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={(errors: any) => console.log(errors)}
                        className={'d-flex f-col'}
                    >
                        <div className={'d-flex'}>
                            <div className={'m-5'}>
                                <TextValidator
                                    className={'m-5'}
                                    id={'firstName'}
                                    label={"First Name"}
                                    onChange={this.onChange}
                                    name={"firstName"}
                                    autoComplete={'off'}
                                    value={firstName}
                                />
                            </div>
                            <div className={'m-5'}>
                                <TextValidator
                                    className={'m-5'}
                                    id={'secondName'}
                                    label={"Second Name"}
                                    onChange={this.onChange}
                                    name={"secondName"}
                                    autoComplete={'off'}
                                    value={secondName}
                                />
                            </div>
                        </div>
                        <div className={'m-5'}>
                            <TextValidator
                                className={'m-5'}
                                id={'userEmail'}
                                label={"Email"}
                                onChange={this.onChange}
                                name={"email"}
                                autoComplete={'off'}
                                value={email}
                                validators={['required', 'isEmail']}
                                errorMessages={['this field is required', 'email is not valid']}
                            />
                        </div>
                        <div className={'m-5'}>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel htmlFor="select-multiple">Assign Project(s)</InputLabel>
                                <Select
                                    multiple
                                    value={projects}
                                    onChange={this.onChange}
                                    input={<Input name='UserProjects' id='select-multiple'/>}
                                    renderValue={(selected: any) => {
                                        return (
                                            <div style={{maxHeight: 48, overflow: 'auto'}}>
                                                {
                                                    this.props.projects
                                                        .filter((el: any) => selected.indexOf(el.id) > -1)
                                                        .map((el: any) => <p key={el.id}>{el.title}</p>)
                                                }
                                            </div>
                                        )
                                    }}
                                    MenuProps={MenuProps}
                                    // inputProps={{
                                    //     name: 'UserProjects',
                                    //    // id: 'UserProjects',
                                    // }}
                                >
                                    {
                                        this.props.projects.map((opt: any) => (
                                            <MenuItem key={opt.id} value={opt.id}>
                                                <Checkbox checked={projects.indexOf(opt.id) > -1}/>
                                                <ListItemText primary={opt.title}/>
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        {/*<div className={'d-flex'}>
                            <div className={'m-5'}>
                                <TextValidator
                                    className={'m-5'}
                                    autoComplete={'off'}
                                    label="Password"
                                    onChange={this.onChange}
                                    name="password"
                                    type="password"
                                    validators={!id ? ['required'] : []}
                                    errorMessages={!id ? ['this field is required'] : []}
                                    value={password}
                                />
                            </div>
                            <div className={'m-5'}>
                                <TextValidator
                                    label="Repeat password"
                                    autoComplete={'off'}
                                    onChange={this.onChange}
                                    name="repeatPassword"
                                    type="password"
                                    validators={!id ? ['isPasswordMatch', 'required'] : ['isPasswordMatch']}
                                    errorMessages={!id || this.state.password ? ['password mismatch', 'this field is required'] : ['password mismatch']}
                                    value={repeatPassword}
                                />
                            </div>
                        </div>*/}

                    </ValidatorForm>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Close
                    </Button>
                    <Button variant="contained" className={'my-btn btn-primary'} onClick={this.handleOk}
                            disabled={disabled || pending}>
                        Save
                        {(disabled || pending) && <Loading/>}
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    userItemEdited: state[moduleName].userItemEdited,
    userItemAdded: state[moduleName].userItemAdded,
    error: errorSelector(state),
    projects: locationsSelector(state),
});

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        fetchLocations,
        showDialogContent,
        addItem: addUser,
        editItem: editUser,
    }, dispatch)
);
const edit = connect(mapStateToProps, mapDispatchToProps)(AddEditUserDialog);
export default edit;
