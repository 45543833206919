import React, {Component} from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PoiIcon from "../../../assets/img/new/POI.svg";
import PoleIcon from '../../../assets/img/new/poles.svg';
import StationIcon from '../../../assets/img/new/station.svg';
import ParcelIcon from '../../../assets/img/new/parcel.svg';
import SegmentIcon from '../../../assets/img/new/Segment.svg';
import ReactSVG from 'react-svg';
import Typography from '@material-ui/core/Typography';
import {makeStyles, Theme, useTheme, createStyles} from '@material-ui/core/styles';
import PolesTable from "./Poles";
import PoisTable from "./Pois";
import Stations from "./Stations";
import ParcelsTable from "./Parcels";
import SegmentsTable from "./Segments";

interface TabContainerProps {
    children?: React.ReactNode;
    dir?: string;
}

function TabContainer({children, dir}: TabContainerProps) {
    return (
        <Typography component="div" dir={dir} style={{padding: 8 * 3}}>
            {children}
        </Typography>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            width: 500,
        },
    }),
);


function HomeTablesPage() {
    const classes = useStyles();
    const theme = useTheme();
    const [selectedTab, setValue] = React.useState(0);

    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setValue(newValue);
    }

    function handleChangeIndex(index: number) {
        setValue(index);
    }

    return (
        <div className={'main-view'}>
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label="Słupy" icon={<ReactSVG src={PoleIcon} className={'svg-icon'}/>}/>
                <Tab label="Stacje" icon={<ReactSVG src={StationIcon} className={'svg-icon'}/>}/>
                <Tab label="Punkty poi" icon={<ReactSVG src={PoiIcon} className={'svg-icon'}/>}/>
                <Tab label="Działki" icon={<ReactSVG src={ParcelIcon} className={'svg-icon'}/>}/>
                <Tab label="Przęsła" icon={<ReactSVG src={SegmentIcon} className={'svg-icon'}/>}/>

            </Tabs>
            {
                selectedTab === 0 && <TabContainer dir={theme.direction}> <PolesTable/></TabContainer>
            }
            {
                selectedTab === 1 && <TabContainer dir={theme.direction}> <Stations/></TabContainer>
            }
            {
                selectedTab === 2 && <TabContainer dir={theme.direction}> <PoisTable/></TabContainer>
            }
            {
                selectedTab === 3 && <TabContainer dir={theme.direction}> <ParcelsTable/></TabContainer>
            }
            {
                selectedTab === 4 && <TabContainer dir={theme.direction}> <SegmentsTable/></TabContainer>
            }
        </div>
    );
}

const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({}, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(HomeTablesPage);
