import React from 'react';
import clsx from 'clsx';
import {createStyles, makeStyles, useTheme, Theme, fade} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {Button, Icon} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import {isSuperAdminSelector, signOut, userSelector} from "../../../../ducks/auth";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Logo from "../../../../assets/img/logo_BKW1@2x.png";
import Projects, {TYPES} from "../Projects";
import Entities from "../Entities";
import Powerlines from "../Powerlines";
import RecordUpdatedFilter from "../Record_UpdatedFilter";
import Logo1 from '../../../../assets/img/pexels-photo-414612.jpeg';
import {NavLink, Link, Route} from 'react-router-dom';
import './index.scss';

import MpViewActiveIcon from "../../../../assets/img/mapview-active.svg";
import TableViewIcon from "../../../../assets/img/tableview-active.svg";
import ReactSVG from 'react-svg';
import ExportData from "./ExportData";
import Search from "./Search";

const drawerWidth = 360;

declare var ExcellentExport: any;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },

        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing(7),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 7),
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 120,
                '&:focus': {
                    width: 200,
                },
            },
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - 490px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        appBarShiftFull: {
            width: `calc(100% - 260px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            padding: '50px 60px'
        },
        drawerpaper: {
            padding: '50px',
            paddingTop: '30px',
            backgroundColor: '#282c34',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: 0,
            padding: 0,
            margin: 0,
            [theme.breakpoints.up('sm')]: {
                width: 0,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        content: {
            position: 'relative',
            flexGrow: 1,
            padding: 0,
        },
    }),
);

function MiniDrawer(props: any) {
    const {children, signOut, isSuperAdmin, user, logs, users}: any = props;
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    function handleProfileMenuOpen(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleMobileMenuClose() {
        setMobileMoreAnchorEl(null);
    }

    function handleMenuClose() {
        setAnchorEl(null);
        handleMobileMenuClose();
    }

    function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
        setMobileMoreAnchorEl(event.currentTarget);
    }

    function handleDrawerOpen() {
        setOpen(true);
    }

    function handleDrawerClose() {
        setOpen(false);
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
            className={'main-menu'}
        >

            {
                isSuperAdmin && (
                    <Link to={'/admin'} onClick={handleMenuClose}> <MenuItem>Admin</MenuItem></Link>
                )
            }
            <MenuItem onClick={signOut}>Log Out</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            {
                isSuperAdmin && (
                    <Link to={'/admin'}> <MenuItem>Admin</MenuItem></Link>
                )
            }
            <MenuItem onClick={signOut}>Log Out</MenuItem>

        </Menu>
    );

    return (
        <div className={classes.root}>
            <CssBaseline/>

            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                    [classes.appBarShiftFull]: !open,
                })}
            >
                <Toolbar style={{position: 'relative'}}>
                    {
                        !open && (
                            <div className={'open-sidebar'}>
                                <IconButton onClick={handleDrawerOpen}>
                                    <ChevronRightIcon style={{color: '#fff'}}/>
                                </IconButton>
                            </div>
                        )
                    }

                    {/*<Route path='/home/map' render={() => (<Search/>)}/>*/}
                    <Search/>
                    <div className={classes.grow}/>
                    <div className={classes.sectionDesktop}>
                        <div className={'d-flex a-c help-info'}>
                            <div className={'d-flex a-c help-info-container'}>

                                <ExportData/>

                                <NavLink to={'/home/map'} className={'view-mode'} activeClassName={'view-selected'}
                                         style={{paddingRight: 0}}>
                                    <IconButton>
                                        <ReactSVG src={MpViewActiveIcon} className={'mode-view pr-0'}/>
                                    </IconButton>
                                </NavLink>
                                <NavLink to={'/home/tables'} className={'view-mode'} activeClassName={'view-selected'}
                                         style={{paddingLeft: 0}}>
                                    <IconButton>
                                        <ReactSVG src={TableViewIcon} className={'mode-view  '}/>
                                    </IconButton>
                                </NavLink>


                                <div className={' d-flex a-c'} onClick={handleProfileMenuOpen}>
                                    <IconButton
                                        edge="end"
                                        aria-label="Account of current user"
                                        aria-controls={menuId}
                                        aria-haspopup="true"
                                        color="inherit"
                                        className={"logo-img"}
                                    >
                                        <img src={Logo1}/>
                                    </IconButton>
                                    <div style={{transform: isMenuOpen ? 'rotate(270deg)' : 'rotate(90deg)'}}
                                         className={'menu-arrow-icon d-flex a-c j-center'}>
                                        <ChevronRightIcon />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="Show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>

            {renderMobileMenu}
            {renderMenu}


            <Drawer
                variant="permanent"

                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx(classes.drawerpaper, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                open={open}
            >


                {
                    open && (
                        <div className={classes.toolbar}>
                            <Link to={'/home'}>
                                <img className={`logo ${!open ? 'full-img' : ''}`} src={Logo}/>
                            </Link>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon style={{color: "white"}}/> : <ChevronLeftIcon style={{color: "white"}}/>}
                            </IconButton>
                        </div>
                    )
                }


                {
                    open && (
                        <React.Fragment>
                            <Route path='/home' render={() => {
                                return (
                                    <React.Fragment>
                                        <Divider style={{marginBottom: 20}}/>
                                        <Projects type={TYPES.PROJECT}/>
                                        <Powerlines/>
                                        <Route path={'/home/map'} render={() => {
                                            return (
                                                <React.Fragment>


                                                    <Entities/>
                                                    <RecordUpdatedFilter/>
                                                </React.Fragment>
                                            )
                                        }}/>

                                    </React.Fragment>
                                )
                            }}/>
                            <Route path='/admin' render={() => {
                                return (
                                    <React.Fragment>

                                        <NavLink to={'/admin/users'}
                                                 activeClassName="selected"><MenuItem>Users</MenuItem></NavLink>
                                        <NavLink to={'/admin/logs'} activeClassName="selected"><MenuItem>Logs</MenuItem></NavLink>
                                        <NavLink to={'/admin/categories'} activeClassName="selected"><MenuItem>Categories</MenuItem></NavLink>
                                    </React.Fragment>
                                )
                            }}/>
                        </React.Fragment>
                    )
                }
            </Drawer>


            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <div className={'main-area'}>
                    {children}
                </div>
            </main>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    user: userSelector(state),
    isSuperAdmin: isSuperAdminSelector(state),
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        signOut,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(MiniDrawer);
