import React, {Component} from 'react';
import './index.scss';
import MapContainer from './map.container';
import {Col, Row} from "antd";

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {bindActionCreators} from "redux";
import {
    changeControls, moduleName,
} from "../../../ducks/map";
import {connect} from "react-redux";
import {fetchLocationPoi} from "../../../ducks/map/poi";
import {fetchLocationParcels} from "../../../ducks/map/parcels";
import {fetchLocationPoles} from "../../../ducks/map/poles";
import {fetchLocationSegments} from "../../../ducks/map/segments";
import {fetchLocationStations} from "../../../ducks/map/stations";


interface MapContainerProps {
    changeControls: any,
    allowAddPoi: any
}

class MapSection extends Component<MapContainerProps,
    {}> {

    componentDidMount(): void {
    }

    private onAllowToAddPoi = () => {
        this.props.changeControls({
            name: 'allowAddPoi',
            value: Date.now()
        });
        this.props.changeControls({
            name: 'showPois',
            value: true
        });
    };

    render() {
        return (
            <div className={'fullWidth'}>
                <Row>
                    {
                        this.props.allowAddPoi && (
                            <div className={'help-area add-poi'}>
                                Kliknij na mapie żeby wybrać lokalizację
                            </div>
                        )
                    }
                    <Col span={12}>
                        <MapContainer/>
                        <div className={'fab-icon-add'}>
                            <Fab color="primary" aria-label="Add" onClick={this.onAllowToAddPoi}>
                                <AddIcon/>
                            </Fab>
                        </div>
                    </Col>
                    <Col span={12}></Col>
                </Row>
            </div>
        );
    }
}


const mapStateToProps = (state: any) => ({
    allowAddPoi: state[moduleName].allowAddPoi,
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        changeControls: changeControls,
        fetchLocationPoi,
        fetchLocationParcels,
        fetchLocationPoles,
        fetchLocationSegments,
        fetchLocationStations,
    }, dispatch)
);
const map = connect(mapStateToProps, mapDispatchToProps)(MapSection);
export default map;
