import React, {Component} from 'react';
import './index.scss';
import {changeControls, errorSelector, locationSelector, locationsSelector} from "../../../../../ducks/map";
import {addPoi, editPoi, removePoi} from "../../../../../ducks/map/poi";
import {showDialogContent} from "../../../../../ducks/dialogs";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";


import {
    moduleName
} from "../../../../../ducks/map";
import {isSuperADMINAdminSelector} from "../../../../../ducks/auth";
import MainModalDialog, {TYPES} from "../main.modal";
import {toast} from "react-toastify";
import {categorySelector} from "../../../../../ducks/admin";

class AddPoiDialog extends MainModalDialog {

    constructor(p: any) {
        super(p);
        this.title = 'Poi';
        this.type = TYPES.POI;
    }

    protected handleOk = async (e: any) => {
        if (this.state.categoryId < 1) {
            toast.error('Category is required!', {
                position: toast.POSITION.TOP_LEFT
            });
        } else {
            try {
                this.setState({__pending: true});
                // this.props.onFinishEditItem();//record.data.data
                const {id}: any = this.state;
                if (id) {
                    await this.props.editItem({
                        ...this.state,
                    });
                } else {
                    this.props.onAddItem({
                        ...this.state,
                        points: this.props.position,
                        projectId: this.props.location.id
                    });
                    this.props.changeControls({
                        name: 'allowAddPoi',
                        value: false
                    });
                }
            } catch (e) {
                toast.error(e.response ? e.response.data.error || e.response.data.message : e.meesage || e, {
                    position: toast.POSITION.TOP_LEFT
                });
            } finally {
                setTimeout(() => {
                    this.setState({__pending: false});
                    this.handleCancel(e);
                }, 1000)
            }
        }
    };

    private onAllowToAddPoi = () => {
        this.props.changeControls({
            name: 'allowAddPoi',
            value: false
        });
        this.props.changeControls({
            name: 'showPois',
            value: true
        });
    };

    protected handleCancel = (e: any) => {
        this.props.showDialogContent(null);
        this.onAllowToAddPoi();
    };

    render() {
        return super._render()
    }
}

const mapStateToProps = (state: any) => ({
    itemsList: state[moduleName].poiList,
    allowAddPoi: state[moduleName].allowAddPoi,
    isAdmin: isSuperADMINAdminSelector(state),
    error: errorSelector(state),
    projects: locationsSelector(state),
    categories: categorySelector(state),
    location: locationSelector(state),
});

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showDialogContent,
        changeControls,
        editItem: editPoi,
        onDeleteItem: removePoi,
        onAddItem: addPoi,
    }, dispatch)
);
const edit = connect(mapStateToProps, mapDispatchToProps)(AddPoiDialog);
export default edit;
