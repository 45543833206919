import React, {Component} from 'react';

import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {fade} from "@material-ui/core/styles";
import ReactSVG from 'react-svg';
import SearchIconStatic from "../../../../../assets/img/search.svg";
import {moduleName, changeSettings, searchSelector} from "../../../../../ducks/auth";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.black, 0),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.black, 0),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing(10),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);


function Search(props: any) {
    const classes = useStyles();
    let timeout: any = null;

    function onSearch(e: any) {
        const value = e.target.value;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            props.changeSettings({
                name: 'search',
                value
            })
        },  props.delay || 2000)//DELAY
    }

    function onKeyDown(e: any) {
        const value = e.target.value;
        if (timeout) clearTimeout(timeout);
        if (e.key === 'Enter') {
            props.changeSettings({
                name: 'search',
                value
            })
        }
    }

    return (
        <div className={classes.search}>
            <div className={"search-input d-flex a-c"}>
                <ReactSVG src={SearchIconStatic} className={'svg-icon'} />
                <input style={{backgroundColor: '#282c34', color: '#fff'}} placeholder={'Szukaj danych'} onKeyDown={onKeyDown} onChange={onSearch}/>
            </div>
        </div>
    )
}


const mapStateToProps = (state: any) => ({
    search: searchSelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        changeSettings,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(Search);
