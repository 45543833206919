import React, {Component} from 'react';
import _ from 'lodash';
import './index.scss';
import {showAlert, showDialogContent} from "../../../../ducks/dialogs";
import {
    Parcel,
    Pole,
    GPSCoordinate,
    Geometry,
    SegmentConductor,
    ParcelConductor, Segment, Station, Poi
} from "../../../../entities";


import {
    locationSelector,
    changeControls,
    locationPolesSelector,
    modesSelector,
    lastGeoPostionsSelector,
    currentModeSelector,
    locationParcelsSelector,
    moduleName,
    locationSegmentsSelector,
    locationStationsSelector,
    locationPoisSelector, powerlineSelector
} from "../../../../ducks/map";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import AddPoiDialog from "./add.poi";
import AddParcelDialog from "./add.parcel";
import AddPoleDialog from "./add.pole";
import AddSegmentDialog from "./add.segment";
import AddStationDialog from "./add.station";
import {SETTINGS} from "../../../../entities/utils";
import {showMarkerMapTooltip, TooltipInfo} from "../../../../ducks/tooltip";
import {fetchLocationPoi} from "../../../../ducks/map/poi";
import {fetchLocationParcels} from "../../../../ducks/map/parcels";
import {fetchLocationPoles} from "../../../../ducks/map/poles";
import {fetchLocationSegments} from "../../../../ducks/map/segments";
import {fetchLocationStations} from "../../../../ducks/map/stations";
import {searchSelector} from "../../../../ducks/auth";
import {statuses, segment_statuses} from "../../../../utils";
import {createPolygon, createCircle, createMarker, createPath} from "./extra";
import {toast} from "react-toastify";
import Dialog from "@material-ui/core/Dialog";


declare var google: any, createPopupClass: any, MarkerClusterer: any;

const MARKER_TYPE: any = {
    POLE: 3,
    POI: 4,
    STATION: 2,
    PARCELS: 1,
    SEGMENTS: 5,
};

interface MapContainerProps {

    project: any,
    selected_powerlines: Array<any>,
    pois: Array<any>,
    parcels: Array<Parcel>,
    poles: Array<Pole>,
    segments: Array<Segment>,
    stations: Array<Station>,

    showMarkerMapTooltip: Function,
    fetchLocationSegments: Function,
    fetchLocationParcels: Function,
    fetchLocationPoi: Function,
    fetchLocationStations: Function,
    fetchLocationPoles: Function,

    mapCenter: any,
    mapZoom: number,
    showPois: boolean,
    showStations: boolean,
    showSegments: boolean,
    showParcels: boolean,
    showPoles: boolean,
    location: any,
    showDialogContent: any,
    tempPosition: any,
    search: string,
    drawMode: string,
    changeControls: Function,
    drawModeList: Array<string>,
    parcelList: any,
    polesList: any,
    segmentList: any,
    stationList: any,
    poiList: any,
    dateFilter: any,
    allowAddPoi: any,
    showAlert: any
}

class MapContainer extends Component<MapContainerProps,
    {}> {
    private drawedStations: Array<any> = [];
    private drawedPois: Array<any> = [];
    private drawedPoles: Array<any> = [];
    private drawedClusters: Array<any> = [];
    private drawedParcels: Array<ParcelConductor> = [];
    private drawedSegments: Array<SegmentConductor> = [];
    private parcelList: any;
    private polesList: any;
    private segmentList: any;
    private stationList: any;
    private poiList: any;
    private googleMap: any;
    private container: any;
    private searchCount: number = 0;
    private searchAvailabelCount: number = 0;

    private ev_X: number = 0;
    private ev_Y: number = 0;
    private events: any = [
        {
            name: 'mousemove',
            cntx: window,
            callback: (e: any) => {
                this.ev_X = e.clientX;
                this.ev_Y = e.clientY;
            }
        }
    ];

    componentDidMount(): void {
        this.drawMap();
        // this.resetDrawMaPoles(this.props.poles, this.props.showPoles);
        const nextProps = this.props;
        this.resetDrawMapStations(nextProps.stations, nextProps.showStations, nextProps.search);
        this.resetDrawMapSegments(nextProps.segments, nextProps.showSegments, nextProps.search);
        this.resetDrawMaPoles(nextProps.poles, nextProps.showPoles, nextProps.search);
        this.resetDrawMapParcels(nextProps.parcels, nextProps.showParcels, nextProps.search);
        this.resetDrawMaPois(nextProps.pois, nextProps.showPois, nextProps.search);
        this.events.forEach((el: any) => {
            el.cntx.addEventListener(el.name, el.callback);
        });
    }

    componentWillUnmount(): void {
        this.events.forEach((el: any) => {
            el.cntx.removeEventListener(el.name, el.callback);
        });
    }

    shouldComponentUpdate(): boolean {
        return false;
    }

    componentWillReceiveProps(nextProps: any, nextContext: any): void {
        const props: any = this.props;

        if (nextProps.stationList !== this.stationList || nextProps.showStations !== this.props.showStations) {
            this.resetDrawMapStations(nextProps.stations, nextProps.showStations, nextProps.search);
            this.stationList = nextProps.stationList;
        }
        if (nextProps.segmentList !== this.segmentList || nextProps.showSegments !== this.props.showSegments) {
            this.resetDrawMapSegments(nextProps.segments, nextProps.showSegments, nextProps.search);
            this.segmentList = nextProps.segmentList;
        }
        if (nextProps.polesList !== this.polesList || nextProps.showPoles !== this.props.showPoles) {
            this.resetDrawMaPoles(nextProps.poles, nextProps.showPoles, nextProps.search);
            this.polesList = nextProps.polesList;
        }
        if (nextProps.parcelList !== this.parcelList || nextProps.showParcels !== this.props.showParcels) {
            this.parcelList = nextProps.parcelList;
            this.resetDrawMapParcels(nextProps.parcels, nextProps.showParcels, nextProps.search);
        }
        if (nextProps.poiList !== this.poiList || nextProps.showPois !== this.props.showPois) {
            this.poiList = nextProps.poiList;
            this.resetDrawMaPois(nextProps.pois, nextProps.showPois, nextProps.search);
        }

        this.searchCount = 0;
        this.searchAvailabelCount = 0;
        [
            'showPois',
            'showParcels',
            'showSegments',
            'showStations',
            'showPois',
        ].forEach((el) => {
            if (nextProps[el] !== props[el]) {
                this.searchAvailabelCount++;
            }
        })

        if (
            nextProps.dateFilter !== this.props.dateFilter ||
            nextProps.search !== this.props.search ||
            nextProps.selected_powerlines.length !== this.props.selected_powerlines.length ||
            this.props.project && nextProps.project.id !== this.props.project.id
        ) {
            this.resetDrawMapStations(nextProps.stations, nextProps.showStations, nextProps.search);
            this.resetDrawMapSegments(nextProps.segments, nextProps.showSegments, nextProps.search);
            this.resetDrawMaPoles(nextProps.poles, nextProps.showPoles, nextProps.search);
            this.resetDrawMapParcels(nextProps.parcels, nextProps.showParcels, nextProps.search);
            this.resetDrawMaPois(nextProps.pois, nextProps.showPois, nextProps.search);
        }
    }


    private setMapOnAll(map: any, markers: any) {
        for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
        }
    }

    private resetDrawMapSegments(_list: Array<Segment>, show: boolean, search: string) {
        this.setMapOnAll(null, this.drawedSegments);
        this.drawedSegments = [];
        for (let i = 0; i < this.drawedClusters.length; i++) {
            if (this.drawedClusters[i].__type === 4) {
                this.drawedClusters.splice(i--, 1)[0].clearMarkers();
            }
        }

        if (!show || !_list.length) return;
        const points: any = [];
        for (let i = 0, list: Array<any> = this.filterItems(_list, search); i < list.length; i++) {
            const el: any = list[i];
            for (let j = 0; j < el.points.coordinates.length; j++) {
                const pathList = [];
                const _points = el.points.coordinates[j];
                for (let k = 0; k < _points.length; k++) {
                    pathList.push(new GPSCoordinate(_points[k]));
                    points.push(pathList[pathList.length - 1]);
                }
                let strokeColor: string = '';
                switch (el.status) {
                    case segment_statuses[0].value: {
                        strokeColor = 'blue';
                        break;
                    }
                    case segment_statuses[1].value: {
                        strokeColor = 'yellow';
                        break;
                    }
                    case segment_statuses[2].value: {
                        strokeColor = 'orange';
                        break;
                    }
                    case segment_statuses[3].value: {
                        strokeColor = 'red';
                        break;
                    }
                    case segment_statuses[4].value: {
                        strokeColor = 'green';
                        break;
                    }
                    case segment_statuses[5].value: {
                        strokeColor = 'grey';
                        break;
                    }
                    case segment_statuses[6].value: {
                        strokeColor = 'magenta';
                        break;
                    }
                }
                const segmentPath = new google.maps.Polyline({
                    editable: false,
                    draggable: false,
                    clickable: true,
                    path: pathList,
                    geodesic: true,
                    ...SETTINGS.ACTIVE.LINE,
                    strokeColor,
                });
                this.applyMarkerEvents(segmentPath, 1, 0.75, MARKER_TYPE.SEGMENTS, el);
                this.drawedSegments.push(segmentPath);
                this.applyEvent(segmentPath, el);
                createPath(segmentPath);
            }
        }
        this.addCluster(4, this.drawedSegments);
        this.fitToView(points);
    }

    private resetDrawMaPois(pois: Array<Poi>, show: boolean, search: string) {
        this.setMapOnAll(null, this.drawedPois);
        this.drawedPois = [];
        if (!show || !pois.length) return;
        const {drawMode, drawModeList, showDialogContent} = this.props;
        const points: any = [];
        this.filterItems(pois, search).forEach((el: Poi, index) => {

            const position: GPSCoordinate = el.points.toGPS();
            const image = '/assets/img/poi.png';
            const poleSettings = new google.maps.Marker({
                position: position,
                // clickable: true,
                // editable: false,
                // draggable: false,
                icon: image
            });
            this.applyMarkerEvents(poleSettings, 1, 0.75, MARKER_TYPE.POI, el);
            this.applyEvent(poleSettings, el);
            points.push(position);


            poleSettings.animation = google.maps.Animation.DROP;

            this.drawedPois.push(poleSettings);
        });
        this.setMapOnAll(this.googleMap, this.drawedPois);
        this.fitToView(points);
    }

    private resetDrawMaPoles(poles: Array<Pole>, show: boolean, search: string) {
        this.setMapOnAll(null, this.drawedPoles);
        this.drawedPoles = [];
        for (let i = 0; i < this.drawedClusters.length; i++) {
            if (this.drawedClusters[i].__type === 3) {
                this.drawedClusters.splice(i--, 1)[0].clearMarkers();
            }
        }


        if (!show || !poles.length) return;
        const list: any = [];
        const points: any = [];
        for (let i = 0, _list: Array<any> = this.filterItems(poles, search); i < _list.length; i++) {
            const el: any = _list[i];
            const point = el.points.toGPS();
            const image = '/assets/img/pole.png';
            const poleCircle = new google.maps.Marker({
                position: point,
                // clickable: true,
                // editable: false,
                // draggable: false,
                icon: image
            });

            points.push(point);
            this.applyMarkerEvents(poleCircle, 1, 0.75, MARKER_TYPE.POLE, el);
            this.drawedPoles.push(poleCircle);
            this.applyEvent(poleCircle, el);
            list.push(createMarker(poleCircle));


        }
        this.addCluster(3, list);
        this.fitToView(points);
    }

    private resetDrawMapStations(station: Array<Station>, show: boolean, search: string) {
        this.setMapOnAll(null, this.drawedStations);
        this.drawedStations = [];
        for (let i = 0; i < this.drawedClusters.length; i++) {
            if (this.drawedClusters[i].__type === 2) {
                this.drawedClusters.splice(i--, 1)[0].clearMarkers();
            }
        }
        if (!show || !station.length) return;

        const stationList: any = [];
        const points: any = [];
        for (let i = 0, list: Array<any> = this.filterItems(station, search); i < list.length; i++) {
            const el: any = list[i];
            const point = el.points.toGPS();
            const image = '/assets/img/station.png';
            const stationCircle = new google.maps.Marker({
                position: point,
                // clickable: true,
                // editable: false,
                // draggable: false,
                icon: image
            });
            points.push(point);
            this.applyMarkerEvents(stationCircle, 1, 0.75, MARKER_TYPE.STATION, el);
            this.applyEvent(stationCircle, el);
            this.drawedStations.push(stationCircle);
            stationList.push(createMarker(stationCircle));
        }
        this.addCluster(2, stationList);
        this.fitToView(points);
    }

    private resetDrawMapParcels(_list: Array<Parcel>, show: boolean, search: string) {
        this.setMapOnAll(null, this.drawedParcels);
        this.drawedParcels = [];

        for (let i = 0; i < this.drawedClusters.length; i++) {
            if (this.drawedClusters[i].__type === 1) {
                this.drawedClusters.splice(i--, 1)[0].clearMarkers();
            }
        }
        if (!show || !_list.length) return;
        const points: any = [];
        const polyList: any = [];
        for (let i = 0, list: Array<any> = this.filterItems(_list, search); i < list.length; i++) {
            const el: any = list[i];
            for (let j = 0, gpsList: any = el.points.coordinates; j < gpsList.length; j++) {
                const _el = gpsList[j];
                const path: any = [];
                for (let k = 0; k < _el.length; k++) {
                    for (let kd = 0; kd < _el[k].length; kd++) {
                        path.push(new GPSCoordinate(_el[k][kd]));
                        points.push(path[path.length - 1]);
                    }
                }
                const polygon = createPolygon(path, el);
                this.applyMarkerEvents(polygon, 1, el.status === statuses[2].value ? 0.3 : 0, MARKER_TYPE.PARCELS, el);
                this.drawedParcels.push(polygon);
                this.applyEvent(polygon, el);
                polyList.push(polygon);
            }
        }
        this.addCluster(1, polyList);
        this.fitToView(points);
    }

    private applyMarkerEvents(marker: any, fillOpacityActive: number = 1, fillOpacityInActive: number = 0, type: number = MARKER_TYPE.PARCELS, el: any) {
        if (marker.setOpacity) {
            marker.setOpacity(fillOpacityInActive);
        }
        const self: MapContainer = this;
        marker.addListener('mouseover', function (e: any) {
            let clientX = self.ev_X,
                clientY = self.ev_Y;
            if (e.wa) {
                const {wa} = e;
                clientX = wa.clientX;
                clientY = wa.clientY;
            }

            if (type === MARKER_TYPE.PARCELS) {
                marker.setOptions({fillOpacity: fillOpacityActive});
            } else if (type === MARKER_TYPE.SEGMENTS) {

                marker.setOptions({strokeOpacity: fillOpacityActive});
            } else {
                marker.setOpacity(fillOpacityActive);
            }
            let text = '';
            switch (type) {
                case  MARKER_TYPE.SEGMENTS: {
                    text = el.przeslo;
                    break;
                }
                case  MARKER_TYPE.PARCELS: {
                    text = el.numer;
                    break;
                }
                case  MARKER_TYPE.POI: {
                    text = el.title;
                    break;
                }
                case  MARKER_TYPE.POLE: {
                    text = el.num_slup;
                    break;
                }
                case  MARKER_TYPE.STATION: {
                    text = el.nazw_stac;
                    break;
                }
            }


            const delta: number = 20;
            self.props.showMarkerMapTooltip(
                new TooltipInfo(clientX + delta, clientY, text)
            );
        });

        marker.addListener('mouseout', function (e: any) {
            self.props.showMarkerMapTooltip(null);
            if (type === MARKER_TYPE.PARCELS) {
                marker.setOptions({
                    fillOpacity: fillOpacityInActive
                });
            } else if (type === MARKER_TYPE.SEGMENTS) {
                marker.setOptions({strokeOpacity: fillOpacityInActive});
            } else {
                marker.setOpacity(fillOpacityInActive);
            }
        });
    }

    private filterItems(list: Array<any>, search: string) {
        let _list = [];
        const keys = list.length ? list[0].keys() : [];
        for (let i = 0; i < list.length; i++) {
            const el: any = list[i];
            if (search) {
                let isInseach = false;
                for (let j = 0; j < keys.length; j++) {
                    const val = el[keys[j]];
                    if (val && val.toString().toLowerCase().match(search.toLowerCase())) {
                        isInseach = true;
                        break;
                    }
                }
                if (!isInseach) continue;
            }
            _list.push(el);
        }
        /*if (_list.length === 0 && ++this.searchCount > this.searchAvailabelCount
        ) {
            toast.error('No elements fount on Map', {
                position: toast.POSITION.TOP_LEFT
            });
        }*/
        return _list;
    }

    private applyEvent(el: any, item: any) {
        const {drawMode, drawModeList, showDialogContent} = this.props;
        google.maps.event.addListener(el, 'click', function () {
            if (item instanceof Parcel) {
                showDialogContent(
                    <AddParcelDialog
                        selectedItem={item}
                    />
                );
            } else if (item instanceof Pole) {
                showDialogContent(
                    <AddPoleDialog
                        selectedItem={item}
                    />
                );
            } else if (item instanceof Segment) {
                showDialogContent(
                    <AddSegmentDialog
                        selectedItem={item}
                    />
                );
            } else if (item instanceof Station) {
                showDialogContent(
                    <AddStationDialog
                        selectedItem={item}
                    />
                );
            } else if (item instanceof Poi) {
                showDialogContent(
                    <AddPoiDialog
                        selectedItem={item}
                        position={new Geometry()}/>
                );
            }
        });
    }

    private addCluster(__type: number, list: Array<any>) {
        const clusterer = new MarkerClusterer(
            this.googleMap,
            list,
            {
                imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
            }
        );
        clusterer.__type = __type;
        clusterer.minClusterSize_ = list.length > 1000 ? 100 : (list.length > 100 ? 10 : list.length);
        this.drawedClusters.push(clusterer);
    }

    private onAllowToAddPoi = () => {
        this.props.changeControls({
            name: 'allowAddPoi',
            value: false
        });
        this.props.changeControls({
            name: 'showPois',
            value: true
        });
    };

    private fitToView(points: Array<any>) {

        if (
            1 ||
            // this.googleMap.lastFocusedProjectId === this.props.location.id &&
            this.googleMap.lastFocusedLength === points.length
        ) return;
        this.googleMap.lastFocusedLength = points.length;
        // this.googleMap.lastFocusedProjectId = this.props.location.id;
        const bounds = new google.maps.LatLngBounds();


        // Extend bounds with each point
        for (var i = 0; i < points.length; i++) {
            bounds.extend(points[i]);
            // new google.maps.Marker({position: points[i], map: map});
        }

        // Apply fitBounds
        this.googleMap.fitBounds(bounds);

        /*// Draw the bounds rectangle on the map
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        if (this.boundingBox) this.boundingBox.setMap(null);
        const boundingBox = this.boundingBox = new google.maps.Polyline({
            path: [
                ne, new google.maps.LatLng(ne.lat(), sw.lng()),
                sw, new google.maps.LatLng(sw.lat(), ne.lng()), ne
            ],
            strokeColor: '#FF0000',
            strokeOpacity: 0.5,
            strokeWeight: 1
        });

        boundingBox.setMap(this.googleMap);*/
    }

    private drawMap() {
        const map = this.googleMap = new google.maps.Map(this.container, {
            zoom: this.props.mapZoom,
            center: this.props.mapCenter
        });

        google.maps.event.addListener(map, 'center_changed', (event: any) => {

            this.props.changeControls({
                name: 'mapCenter',
                value: {
                    lat: map.getCenter().lat(),
                    lng: map.getCenter().lng(),
                }
            });
        });

        google.maps.event.addListener(map, 'click', (event: any) => {
            const {location, showAlert, showDialogContent} = this.props;

            if (!location) {
                return showAlert('Proszę wybrać projekt');
            }
            if (this.props.allowAddPoi) {
                const coordinate = [
                    event.latLng.lng(),
                    event.latLng.lat()
                ];

                showDialogContent(
                  <AddPoiDialog
                      selectedItem={new Poi({projectId: this.props.project ? this.props.project.id : -1})}
                      position={new Geometry(Geometry.TYPE.POINT, coordinate)}/>
                );
            }

            this.onAllowToAddPoi();
        });
        google.maps.event.addListener(map, 'zoom_changed', async () => {
            const project = this.props.location;
            this.props.changeControls({
                name: 'mapZoom',
                value: map.getZoom()
            });
            if (!project) return;
            // await this.props.fetchLocationPoles(project);
            // await this.props.fetchLocationSegments(project);
            // await this.props.fetchLocationStations(project);
            // await this.props.fetchLocationPoi(project);
            // await this.props.fetchLocationParcels(project);
        });
    }

    render() {
        return (
            <div>
                <div className={'map-store'} ref={(e) => this.container = e}></div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    project: locationSelector(state),
    selected_powerlines: powerlineSelector(state),
    search: searchSelector(state),
    mapCenter: state[moduleName].mapCenter,
    mapZoom: state[moduleName].mapZoom,
    dateFilter: state[moduleName].dateFilter,
    allowAddPoi: state[moduleName].allowAddPoi,
    poiList: state[moduleName].poiList,
    segmentList: state[moduleName].segmentList,
    stationList: state[moduleName].stationList,
    polesList: state[moduleName].polesList,
    parcelList: state[moduleName].parcelList,
    showStations: state[moduleName].showStations,
    showSegments: state[moduleName].showSegments,
    showParcels: state[moduleName].showParcels,
    showPoles: state[moduleName].showPoles,
    showPois: state[moduleName].showPois,
    pois: locationPoisSelector(state),
    tempPosition: lastGeoPostionsSelector(state),
    location: locationSelector(state),
    segments: locationSegmentsSelector(state),
    poles: locationPolesSelector(state),
    parcels: locationParcelsSelector(state),
    stations: locationStationsSelector(state),
    drawMode: currentModeSelector(state),
    drawModeList: modesSelector(state),
});

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showMarkerMapTooltip,
        changeControls: changeControls,
        showDialogContent,
        fetchLocationPoi,
        fetchLocationParcels,
        fetchLocationPoles,
        fetchLocationSegments,
        fetchLocationStations,
        showAlert
    }, dispatch)
);
const map = connect(mapStateToProps, mapDispatchToProps)(MapContainer);
export default map;






