import React, {Component} from 'react';
import './index.scss';
import {
    errorSelector,
    lastGeoPostionsSelector,
    locationSelector,
    changeControls,
    moduleName
} from "../../../../../ducks/map";
import {addSegments, editSegments} from "../../../../../ducks/map/segments";
import {showDialogContent} from "../../../../../ducks/dialogs";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {isSuperADMINAdminSelector, isSuperAdminSelector} from "../../../../../ducks/auth";

import MainModalDialog, {TYPES} from "../main.modal";

class AddSegmentDialog extends MainModalDialog {

    constructor(p: any) {
        super(p);
        this.title = 'Przęsła';
        this.type = TYPES.SEGMENT;
        this.editTitle = false;
    }

    render() {
        return super._render();
    }
}

const mapStateToProps = (state: any) => ({
    itemsList: state[moduleName].segmentList,
    error: errorSelector(state),
    location: locationSelector(state),
    isAdmin: isSuperADMINAdminSelector(state),
    tempPosition: lastGeoPostionsSelector(state)
});

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        changeControls: changeControls,
        showDialogContent,
        editItem: editSegments,
        addItem: addSegments,
    }, dispatch)
);
const edit = connect(mapStateToProps, mapDispatchToProps)(AddSegmentDialog);
export default edit;
