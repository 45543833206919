import React, {Component} from 'react';
import './index.scss';
import {
    locationSelector,
    moduleName, powerlineSelector, powerlinesSelector
} from "../../../../ducks/map";
import {bindActionCreators} from "redux";

import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import {fetchProjectPowerlines} from "../../../../ducks/map/powerlines";
import {showDialogContent} from "../../../../ducks/dialogs";
import {fetchLocationPoi} from "../../../../ducks/map/poi";
import {fetchLocationParcels} from "../../../../ducks/map/parcels";
import {fetchLocationPoles} from "../../../../ducks/map/poles";
import {fetchLocationSegments} from "../../../../ducks/map/segments";
import {changeControls} from "../../../../ducks/map";
import {connect} from "react-redux";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactSVG from 'react-svg'
import SearchIconStatic from "../../../../assets/img/search.svg";


interface MapItemProps {
    selected_powerlines: Array<number>,
    project: any,
    powerlines: Array<any>,
    fetchLocationSegments: Function,
    changeControls: Function,
    fetchProjectPowerlines: Function,
    fetchLocationParcels: Function,
    fetchLocationPoi: Function,
    fetchLocationPoles: Function,
}

class Powerlines extends Component<MapItemProps,
    { isAll: boolean, search: string }> {

    state = {
        search: '',
        isAll: false
    }

    componentWillReceiveProps(nextProps: Readonly<MapItemProps>, nextContext: any): void {
        if (!this.props.project && nextProps.project || this.props.project && nextProps.project.id !== this.props.project.id) {
            this.props.fetchProjectPowerlines(nextProps.project);
        }
    }

    private onChange = async (item: any) => {

        let list: Array<number> = this.props.selected_powerlines;
        if (!item) {
            list = this.state.isAll ? [] : [...this.props.powerlines.map(el => el.id)];
            this.props.changeControls({
                name: 'selected_powerlines',
                value: [...list]
            });
            this.setState({
                isAll: !this.state.isAll
            });
            return this.props.powerlines.forEach((el: any) => {
                this.loadProjectData(el);
            });
        }

        for (let i = 0; i < list.length; i++) {
            if (list[i] === item.id) {
                list.splice(i, 1);
                this.props.changeControls({
                    name: 'selected_powerlines',
                    value: [...list]
                });
                return;
            }
        }

        list.push(item.id);
        this.props.changeControls({
            name: 'selected_powerlines',
            value: [...list]
        });
        list.push(item.id);
        this.loadProjectData(item);

    };
    private loadProjectData = async (item: any) => {
        const reqData = {...this.props.project, powerLineId: item.id};
        await this.props.fetchLocationParcels(reqData);
        await this.props.fetchLocationPoles(reqData);
        await this.props.fetchLocationSegments(reqData);
    };
    private onSearch = (e: any) => {
        this.setState({
            search: e.target.value
        })
    }


    render() {
        if (!this.props.project) return null;
        const {selected_powerlines, powerlines} = this.props;
        const _checkers: any = [
            {
                title: 'All',
                selected: this.state.isAll,
                id: -1
            },
            ...powerlines.filter((el: any) => {
                if (this.state.search) {
                    const search = this.state.search.toString().toLowerCase();
                    const keys = Object.keys(el);
                    for (let i = 0; i < keys.length; i++) {
                        const val = el[keys[i]];
                        if (val && val.toString().toLowerCase().match(search)) {
                            return true
                        }
                    }
                    return false
                } else {
                    return true
                }
            }).map((el: any) => {
                const selected: boolean = selected_powerlines.indexOf(el.id) > -1;
                return {
                    title: el.title,
                    powerline: el,
                    id: el.id,
                    selected,
                }
            })
        ];

        return (
            <div>
                <div className={'d-flex f-col entities-info'} key={this.props.project.id}>
                    <div>
                        <span className={'text-light'}>Wybierz linie:</span>
                        <div className={"search-input d-flex a-c j-center"}>
                            <ReactSVG src={SearchIconStatic} className={'svg-icon'}/>
                            <input
                                placeholder={'Search power line(s)...'}
                                style={{borderBottom: '1px solid white', backgroundColor: '#282c34', color: '#fff'}}
                                onChange={this.onSearch}
                            />
                        </div>
                    </div>
                    <br/>
                    <div className={'d-flex f-col powerline-list-view'}>
                        {
                            _checkers.map((el: any) => {
                                return (
                                    <FormControlLabel
                                        key={el.id}
                                        control={
                                            <Checkbox
                                                checked={el.selected}
                                                onChange={() => this.onChange(el.powerline)}
                                                value={el.id}
                                                color={'primary'}
                                                className={`my-checkbox ${el.selected ? 'checked' : ''}`}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                            />
                                        }
                                        label={
                                            <div className={'d-flex f-row a-c t'} key={el.id}>
                                                <span
                                                    className={`${!el.selected ? 'text-light' : ' fon-bold'} d-flex a-c`}>
                                                    <span className={'text-overflow'}
                                                          title={el.title}>{el.title}</span>
                                                </span>
                                            </div>
                                        }
                                    />
                                )
                            })
                        }
                    </div>
                </div>
                <Divider/>
            </div>
        );

    }
}


const mapStateToProps = (state: any) => {
    return {
        project: locationSelector(state),
        powerlines: powerlinesSelector(state),
        selected_powerlines: powerlineSelector(state),
        error: state[moduleName].error,
    }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        changeControls: changeControls,
        fetchProjectPowerlines,
        fetchLocationPoi,
        fetchLocationParcels,
        fetchLocationPoles,
        fetchLocationSegments,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(Powerlines);
