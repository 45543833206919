import {Map} from 'immutable';
import {User} from "../entities";

const _Map: any = Map;
export const arrayToMap = (arr: Array<any>, DataModule: any, key: string = 'ClinicalTrialID') => {
    return arr.reduce((
        acc: any, item: any) => acc.set(item[key], DataModule ? new DataModule(item) : item),
        new _Map({}),
    );
};


export const exportToCsv = function (filename: string, args: any) {
    let result: any, ctr: any, keys: any, columnDelimiter: any, lineDelimiter: any, data;

    data = args.data || null;
    if (data == null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ';';
    lineDelimiter = args.lineDelimiter || '\n';

    const exclude = ['points'];
    keys = Object.keys(data[0]).filter((el) => exclude.indexOf(el) < 0);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item: any) {
        ctr = 0;
        keys.forEach(function (key: any) {
            if (ctr > 0) result += columnDelimiter;

            if(item[key] instanceof User) {
                result += item[key].email
            } else {
                result += item[key];
            }

            ctr++;
        });
        result += lineDelimiter;
    });

    var blob = new Blob([result], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    return result;
}

export const _exportToCsv = function (filename: string, rows: Array<any>) {
    console.log(JSON.stringify(rows));
    var processRow = function (row: any) {
        var finalVal = '';
        const delimeter = ";";
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            }
            var result = innerValue.replace(/"/g, '""');
            // if (result.search(/("|,|\n)/g) >= 0)
            //     result = '"' + result + '"';
            if (j > 0)
                finalVal += delimeter;
            finalVal += result;
        }
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export const statuses = [
    {
        value: 1,
        text: 'Niezweryfikowane'
    },
    {
        value: 2,
        text: 'Zgoda'
    },
    {
        value: 3,
        text: 'Brak Zgody'
    },
];

export const parcel_ownership = [
    {
        value: 'Forest',
        text: 'Forest'
    },
    {
        value: 'Waters',
        text: 'Waters'
    },
    {
        value: 'Roads',
        text: 'Roads'
    },
    {
        value: 'Private',
        text: 'Private'
    },
    {
        value: 'Other',
        text: 'Other'
    }
];

export const segment_statuses = [
    {
        value: 'puste',
        text: 'Puste'
    },
    {
        value: 'zadrzewione',
        text: 'Zadrzewione'
    },
    {
        value: 'pilne',
        text: 'Pilne'
    },
    {
        value: 'wylaczenie',
        text: 'Wylaczenie'
    },
    {
        value: 'serwis',
        text: 'Serwis'
    },
    {
        value: 'niezweryfikowane',
        text: 'Niezweryfikowane'
    },
    {
        value: 'brak zgody',
        text: 'Brak Zgody'
    },
];

export const segment_operation_type = [
    {
        value: 'mulczer',
        text: 'mulczer'
    },
    {
        value: 'żyrafa',
        text: 'żyrafa'
    },
    {
        value: 'piła',
        text: 'piła'
    },
    {
        value: 'arboryści',
        text: 'arboryści'
    },
];
