import React, {Component} from 'react';
import './index.scss';
import {
    changeControls,
    locationParcelsSelector,
    locationPoisSelector,
    locationPolesSelector,
    locationSegmentsSelector,
    locationStationsSelector,
    moduleName
} from "../../../../ducks/map";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Parcel, Poi, Pole, Segment, Station} from "../../../../entities";
import StationIcon from "../../../../assets/img/new/station.svg";
import PoleIcon from "../../../../assets/img/new/poles.svg";
import PoiIcon from "../../../../assets/img/new/POI.svg";
import ParcelIcon from "../../../../assets/img/new/parcel.svg";
import SegmentIcon from "../../../../assets/img/new/Segment.svg";
import ReactSVG from 'react-svg';
import Title from "../../../../components/title";
import {statuses, segment_statuses} from "../../../../utils";
import Pois from "./Pois";

interface MapProjectsProps {
    parcelStatusSelected: Array<number>,
    segmentsStatusSelected: Array<number>,
    pois: Array<Poi>,
    poles: Array<Pole>,
    segments: Array<Segment>,
    parcels: Array<Parcel>,
    stations: Array<Station>,
    changeControls: Function,
    showPois: boolean,
    showStations: boolean,
    showSegments: boolean,
    showParcels: boolean,
    showPoles: boolean
}

class Entities extends Component<MapProjectsProps,
    {
        powerLines: number,
    }> {

    state = {
        powerLines: 0
    }
    private handleChange = (name: string) => {
        return (e: any) => {
            this.props.changeControls({name, value: e.target.checked})
        }
    };
    private handleChangeSubFilter = (name: string, list: any) => {
        return (e: any) => {
            const props: any = this.props;
            const itemFilter = list.filter((el: any) => el.text === e.target.name)[0];
            const value: any = props[name];

            const indexInStore = value.indexOf(itemFilter.value);
            if (indexInStore < 0) {
                value.push(itemFilter.value);
            } else {
                value.splice(indexInStore, 1);
            }
            this.props.changeControls({name, value: [...value]});
            if (name.match('segment')) {
                this.props.changeControls({name: 'segmentList', value: Date.now()});
            } else if (name.match('parcel')) {
                this.props.changeControls({name: 'parcelList', value: Date.now()});
            }

        }
    };
    private filterItems = (e: any) => {
        const newState: any = {
            [e.target.name]: e.target.value
        };
        this.setState(newState);
    }

    render() {
        // return null;
        const {
            parcelStatusSelected,
            segmentsStatusSelected,
            pois,
            poles,
            segments,
            parcels,
            stations,
            showPois,
            showStations,
            showSegments,
            showParcels,
            showPoles
        } = this.props;
        const props: any = this.props;

        const checkers: any = [
            {
                name: 'showPoles',
                label: (
                    <div className={'d-flex f-row a-c'}>
                        <span className={`${!showPoles ? 'text-light' : 'fon-bold'} d-flex a-c`}><Title>Słupy ({poles.length})</Title></span>
                        <ReactSVG src={PoleIcon} className={'svg-icon'}/>
                    </div>
                )
            },
            {
                name: 'showSegments',
                label: (
                    <div className={'d-flex f-row a-c'}>
                        <span className={`${!showSegments ? 'text-light' : 'fon-bold'} d-flex a-c`}><Title>Przęsła ({segments.length})</Title></span>
                        <ReactSVG src={SegmentIcon} className={'svg-icon'}/>
                    </div>
                ),
                selected: segmentsStatusSelected,
                subName: 'segmentsStatusSelected',
                children: segment_statuses
            },
            {
                name: 'showStations',
                label: (
                    <div className={'d-flex f-row a-c'}>
                        <span className={`${!showStations ? 'text-light' : 'fon-bold'} d-flex a-c`}><Title>Stacje ({stations.length})</Title></span>
                        <ReactSVG src={StationIcon} className={'svg-icon'}/>
                    </div>
                )
            },
            {
                name: 'showParcels',
                label: (
                    <div className={'d-flex f-row a-c'}>
                        <span className={`${!showParcels ? 'text-light' : 'fon-bold'} d-flex a-c`}><Title>Działki ({parcels.length})</Title></span>
                        <ReactSVG src={ParcelIcon} className={'svg-icon'}/>
                    </div>
                ),
                selected: parcelStatusSelected,
                subName: 'parcelStatusSelected',
                children: statuses
            },
            {
                name: 'showPois',
                childrenComponent: <Pois/>
            },

        ];
        return (
            <div>
                <div className={'d-flex f-col entities-info'}>
                    <span className={'text-light'}>Warstwy:</span>
                    <br/>
                    <div className={'d-flex f-col'}>
                        {
                            checkers.map((el: any) => {
                                if (el.childrenComponent) {
                                    return <div key={el.name}>{el.childrenComponent}</div>
                                }
                                return (
                                    <div key={el.name}>
                                        <FormControlLabel

                                            control={
                                                <Checkbox
                                                    checked={props[el.name]}
                                                    onChange={this.handleChange(el.name)}
                                                    value={el.name}
                                                    color={'primary'}
                                                    className={`my-checkbox ${props[el.name] ? 'checked' : ''}`}
                                                    inputProps={{
                                                        'aria-label': 'primary checkbox',
                                                    }}
                                                />
                                            }
                                            label={el.label}
                                        />
                                        {
                                            el.children && props[el.name] && (
                                                <div className={'d-flex f-col entities-info'}
                                                     style={{paddingLeft: 15}}>
                                                    <span className={'text-light'}>Status:</span>
                                                    <br/>
                                                    <div className={'d-flex f-col'}>
                                                        {
                                                            el.children.map((suItem: any) => {
                                                                const checked: boolean = el.selected.indexOf(suItem.value) > -1;
                                                                return (
                                                                    <FormControlLabel
                                                                        key={suItem.value}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={checked}
                                                                                onChange={this.handleChangeSubFilter(el.subName, el.children)}
                                                                                value={suItem.text}
                                                                                name={suItem.text}
                                                                                color={'primary'}
                                                                                className={`my-checkbox ${checked ? 'checked' : ''}`}
                                                                                inputProps={{
                                                                                    'aria-label': 'primary checkbox',
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={
                                                                            <div className={'d-flex f-row a-c'}>
                                                                                <span
                                                                                    className={`${!checked ? 'text-light' : 'fon-bold'} d-flex a-c`}>
                                                                                    <Title>{suItem.text}</Title>
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                    />
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>

                                )
                            })
                        }
                        {/*<Slider*/}
                        {/*value={this.state.powerLines}*/}
                        {/*onChange={this.filterItems}*/}
                        {/*valueLabelDisplay="auto"*/}
                        {/*name={'powerLines'}*/}
                        {/*aria-labelledby="range-slider"*/}
                        {/*/>*/}
                    </div>
                </div>
                <Divider/>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        parcelStatusSelected: state[moduleName].parcelStatusSelected,
        segmentsStatusSelected: state[moduleName].segmentsStatusSelected,
        showPois: state[moduleName].showPois,
        showStations: state[moduleName].showStations,
        showSegments: state[moduleName].showSegments,
        showParcels: state[moduleName].showParcels,
        showPoles: state[moduleName].showPoles,
        pois: locationPoisSelector(state),
        segments: locationSegmentsSelector(state),
        poles: locationPolesSelector(state),
        parcels: locationParcelsSelector(state),
        stations: locationStationsSelector(state),
        error: state[moduleName].error,
    }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        changeControls: changeControls
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(Entities);
