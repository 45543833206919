import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reqResetPsw, moduleName, changeSettings} from '../../../ducks/auth';
import './login.scss';
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";

import Loading from "../../../components/loading";
import Logo from "../../../assets/img/logo_BKW1@2x.png";

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

interface MatchParams {
    MemberID: string;
}

interface Props extends RouteComponentProps<MatchParams> {
    reqResetPsw: any,
    authError: boolean,
    loading: boolean,
    changeSettings: Function,
    refreshed: number
}

class ForgotPsw extends Component<Props,
    {}> {

    state = {
        error: false,
        email: '',
    };


    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        if (nextProps.refreshed !== this.props.refreshed) {
            this.props.history.push("/login");
        }
    }

    componentWillUnmount(): void {
        this.props.changeSettings({})
    }

    onInput = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value,
            error: false,
        });
    };

    onSubmitForm = async (e: any) => {
        const form: any = this.refs.form;
        form.submit();
    }
    onSubmit = async (e: any) => {
        // e.preventDefault();
        // e.stopPropagation();
        const form: any = this.refs.form;
        form.isFormValid().then(async (valid: any) => {
            if (valid) {
                try {
                    await this.props.reqResetPsw(this.state);
                } catch (e) {
                    this.setState({
                        error: 'Error! User didn`t found',
                    });
                }
            } else {
                this.setState({
                    disabled: true,
                });
            }
        })
    };

    render() {
        const {authError} = this.props;
        return (
            <div className={'first-page d-flex a-c j-c'}>
                <div className={'main-page-view text-center'}>
                    <img className={`logo `} src={Logo}/>

                    <Typography className={'title'} variant="h6" noWrap>
                        Zresetuj hasło
                    </Typography>

                    <ValidatorForm
                        ref="form"
                        onSubmit={this.onSubmit}
                        onError={(errors: any) => console.log(errors)}
                        className={'d-flex f-col'}
                    >
                        <FormControl>
                            <TextValidator
                                autoComplete={'off'}
                                type={'email'}
                                label={'Email'}
                                id={'email'}
                                placeholder={'Wpisz email'}
                                value={this.state.email}
                                name={'email'}
                                onChange={this.onInput}
                                margin="normal"
                                validators={['required', 'isEmail']}
                                errorMessages={['this field is required', 'email is not valid']}
                            />
                        </FormControl>
                    </ValidatorForm>
                    <div className={'d-flex f-col'}>
                        <div className={'d-flex j-end'}>
                            <Link to="/" className={'link'}>Powrót do logowania</Link>
                        </div>
                        <div className={'d-flex j-center'}>
                            <Button
                                variant="contained"
                                onClick={this.onSubmitForm}
                                className={'my-btn btn-primary'}
                                style={{minWidth: 160, textTransform: "uppercase"}}
                            >
                                Zresetuj hasło!
                                {this.props.loading && <Loading/>}
                            </Button>
                        </div>
                    </div>
                    {
                        authError && (<p style={{color: 'red'}} className={'error-message'}>
                            {authError}
                        </p>)
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    refreshed: state[moduleName].refreshed,
    authError: state[moduleName].error,
    loading: state[moduleName].loading,
});

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        changeSettings,
        reqResetPsw,
    }, dispatch)
);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPsw));
