import React, {Component} from 'react';
import './index.scss';

import {
    locationPoisSelector,
    locationPolesSelector,
    locationSegmentsSelector,
    locationSelector,
    moduleName, powerlineSelector
} from "../../../../ducks/map";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import MainTable from "../MainTable";
import {showDialogContent} from "../../../../ducks/dialogs";
import {API} from "../../../../config";
import Title from "../../../../components/title";
import AddSegmentDialog from "../../../components/Map/map.container/add.segment";
import {fetchLocationMorePoles, deletePole} from "../../../../ducks/map/poles";
import {deleteSegments, fetchLocationMoreSegments} from "../../../../ducks/map/segments";

class SegmentsTable extends MainTable {

    constructor(p: any) {
        super(p);
        const columns: any = [//nazwa_ciagu_id, NAZWA_TAB, nazwa_linii
            {name: 'id', title: 'Id', getCellValue: (row: any) => <Title>{row.id}</Title>},
            {name: 'status', title: 'Status', getCellValue: (row: any) => <Title>{row.status}</Title>},
            {name: 'comment', title: 'Komentarz', getCellValue: (row: any) => <Title>{row.comment}</Title>},
            {
                name: 'nazwa_ciagu_id',
                title: 'nazwa_ciagu_id',
                getCellValue: (row: any) => <Title>{row.nazwa_ciagu_id}</Title>
            },
            // {name: 'NAZWA_TAB', title: 'NAZWA_TAB', getCellValue: (row: any) => <Title>{row.num_slup}</Title>},
            // {name: 'nazwa_linii', title: 'nazwa_linii', getCellValue: (row: any) => <Title>{row.nazwa_linii}</Title>},
        ];
        const tableColumnExtensions: any = [
            {columnName: 'id', width: 200},
            {columnName: 'status', width: 200},
            {columnName: 'comment', width: 200},
            {columnName: 'nazwa_ciagu_id', width: 220},
            // {columnName: 'NAZWA_TAB', width: 220},
            // {columnName: 'nazwa_linii', width: 220}
        ];
        this.state = {
            ...this.state,
            tableColumnExtensions,
            columns,
            title: 'Segments'
        };
    }

    componentDidMount(): void {
        super.componentDidMount();

    }


    protected URL = (): string => {
        // if (this.props.powerLine) {
        //     return `${API}api/projects/${this.props.project ? this.props.project.id : -1}/powerlines/${this.props.powerLine.id}/parcels`;
        // }
        return `${API}api/projects/${this.props.project ? this.props.project.id : -1}/segments`;
    };


    protected onEditItem = (item: any, onFinishEditItem: Function = () => false) => {
        const {showDialogContent} = this.props;
        showDialogContent(
            <AddSegmentDialog
                selectedItem={item}
                onFinishEditItem={() => this.callbacks.push(onFinishEditItem)}
            />
        );
    };


    render() {
        return super._render();
    }
}

const mapStateToProps = (state: any) => ({
    itemsList: state[moduleName].segmentList,
    project: locationSelector(state),
    // powerLine: powerlineSelector(state),
    rows: locationSegmentsSelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showDialogContent,
        onLoadMoreItems: fetchLocationMoreSegments,
        onDeleteItem: deleteSegments,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(SegmentsTable);
