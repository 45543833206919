import React, {Component} from 'react';
import {
    category_poi_selected,
    changeControls,
    locationParcelsSelector,
    locationPoisSelector,
    locationPolesSelector,
    locationSegmentsSelector,
    locationStationsSelector,
    moduleName
} from "../../../../../ducks/map";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Category, Parcel, Poi, Pole, Segment, Station} from "../../../../../entities";
import PoiIcon from "../../../../../assets/img/new/POI.svg";
import ReactSVG from 'react-svg';
import Title from "../../../../../components/title";
import {categorySelector} from "../../../../../ducks/admin";
import SearchIconStatic from "../../../../../assets/img/search.svg";

interface MapProjectsProps {
    pois: Array<Poi>,
    category_poi_selected: Array<number>,
    categories: Array<Category>,
    changeControls: Function,
    showPois: boolean,
}

class Pois extends Component<MapProjectsProps,
    {
        search: string,
    }> {

    state = {
        search: '',
    }
    private handleChange = (name: string) => {
        return (e: any) => {
            this.props.changeControls({name, value: e.target.checked})
        }
    };
    private handleChangeSubFilter = (name: string, list: any) => {
        return (e: any) => {
            const props: any = this.props;
            const id = parseInt(e.target.name);
            const itemFilter = list.filter((el: any) => el.id === id)[0];
            const value: any = props[name];

            const indexInStore = value.indexOf(itemFilter.id);
            if (indexInStore < 0) {
                value.push(itemFilter.id);
            } else {
                value.splice(indexInStore, 1);
            }
            this.props.changeControls({name, value: [...value]});
            if (name.match('poi')) {
                this.props.changeControls({name: 'poiList', value: Date.now()});
            }

        }
    };

    private onSearch = (e: any) => {
        this.setState({
            search: e.target.value
        })
    }

    render() {
        const {
            pois,
            showPois,
            categories,
            category_poi_selected,
        } = this.props;
        const props: any = this.props;

        return (
            <React.Fragment>
                <FormControlLabel

                    control={
                        <Checkbox
                            checked={props['showPois']}
                            onChange={this.handleChange('showPois')}
                            value={'showPois'}
                            color={'primary'}
                            className={`my-checkbox ${props['showPois'] ? 'checked' : ''}`}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                        />
                    }
                    label={(
                        <div className={'d-flex f-row a-c'}>
                            <span
                                className={`${!showPois ? 'text-light' : 'fon-bold'} d-flex a-c`}>
                                <Title>Punkty POI ({pois.length})</Title>
                            </span>
                            <ReactSVG src={PoiIcon} className={'svg-icon'}/>
                        </div>
                    )}
                />
                {
                    showPois ? (
                        <div className={'d-flex f-col entities-info'}
                             style={{paddingLeft: 15}}>
                            <div>
                                <span className={'text-light'}>Wybierz kategorię:</span>
                                <div className={"search-input d-flex a-c j-center"}>
                                    <ReactSVG src={SearchIconStatic} className={'svg-icon'}/>
                                    <input
                                        placeholder={'Search poi(s)...'}
                                        className={'search-field'}
                                        style={{borderBottom: '1px solid white', backgroundColor: '#282c34', color: '#fff'}}
                                        onChange={this.onSearch}
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className={'d-flex f-col powerline-list-view'}>
                                {
                                    categories.filter((el: Category) => {
                                        if (this.state.search) {
                                            return el.title.toLowerCase().match(this.state.search.toLowerCase())
                                        } else {
                                            return true
                                        }
                                    }).map((suItem: Category) => {
                                        const checked: boolean = category_poi_selected.indexOf(suItem.id) > -1;
                                        return (
                                            <FormControlLabel
                                                key={suItem.id}
                                                control={
                                                    <Checkbox
                                                        checked={checked}
                                                        onChange={this.handleChangeSubFilter('category_poi_selected', categories)}
                                                        value={'category_poi_selected'}
                                                        name={suItem.id + ''}
                                                        color={'primary'}
                                                        className={`my-checkbox ${checked ? 'checked' : ''}`}
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <div className={'d-flex f-row a-c'}>
                                                        <span
                                                            className={`${!checked ? 'text-light' : 'fon-bold'} d-flex a-c`}>
                                                            <Title>{suItem.title}</Title>
                                                        </span>
                                                    </div>
                                                }
                                            />
                                        )
                                    })
                                }

                            </div>
                        </div>
                    ) : null
                }
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        showPois: state[moduleName].showPois,
        pois: locationPoisSelector(state),
        category_poi_selected: category_poi_selected(state),
        categories: categorySelector(state),
        error: state[moduleName].error,
    }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        changeControls: changeControls
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(Pois);
