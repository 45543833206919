import React, {Component} from 'react';
import './index.scss';

import {signOut, userSelector} from '../../../ducks/auth';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import Map from "../../components/Map";


class HomeMapPage extends Component<{ signOut: any, user: any },
    {}> {

    render() {
        return (
            <Map/>
        );
    }
}

const mapStateToProps = (state: any) => ({
    user: userSelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        signOut,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(HomeMapPage);
