import React, {Component} from 'react';
import './index.scss';

import {fetchLocationPoi, removePoi, fetchLocationMorePoi} from "../../../../ducks/map/poi";
import {locationPoisSelector, locationSelector, moduleName} from "../../../../ducks/map";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import MainTable from "../MainTable";
import {showDialogContent} from "../../../../ducks/dialogs";
import {API} from "../../../../config";
import AddPoiDialog from "../../../components/Map/map.container/add.poi";

class PoisTable extends MainTable {

    constructor(p: any) {
        super(p);
        const columns: any = [
            {name: 'id', title: 'Id', getCellValue: (row: any) => row.id},
            {name: 'title', title: 'Nazwa', getCellValue: (row: any) => row.title},
            {name: 'comment', title: 'Komentarz', getCellValue: (row: any) => row.comment},
        ];
        const tableColumnExtensions: any = [
            {columnName: 'id', width: 70},
            {columnName: 'title', width: 200},
            {columnName: 'comment', width: 220}
        ];
        this.state = {
            ...this.state,
            tableColumnExtensions,
            columns,
            title: 'Pois'
        };
    }

    protected URL = (): string => {
        return `${API}api/projects/${this.props.project ? this.props.project.id : -1}/poi`;
    };
    protected onEditItem = (item: any, onFinishEditItem: Function = () => false) => {
        const {showDialogContent} = this.props;
        showDialogContent(
            <AddPoiDialog
                selectedItem={item}
                onFinishEditItem={() => this.callbacks.push(onFinishEditItem)}
            />
        );
    };

    componentDidMount(): void {
        super.componentDidMount();

    }

    render() {
        return super._render();
    }
}

const mapStateToProps = (state: any) => ({
    itemsList: state[moduleName].poiList,
    project: locationSelector(state),
    rows: locationPoisSelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showDialogContent,
        onLoadMoreItems: fetchLocationMorePoi,
        onLoadItems: fetchLocationPoi,
        onDeleteItem: removePoi,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(PoisTable);
