import React, {Component} from 'react';
import './index.scss';
import {addCategory, editCategory} from "../../../../ducks/admin/categories";
import {errorSelector, moduleName} from "../../../../ducks/admin";
import {showDialogContent} from "../../../../ducks/dialogs";
import {bindActionCreators} from "redux";
import {connect, Provider} from "react-redux";

import {checkError} from '../../../utils';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import {
    Category,
    User
} from "../../../../entities";
import TextField from "../../../components/Map/map.container/main.modal";


interface ItemPageProps {
    categoryItemEdited: any,
    categoryItemAdded: any,
    error: any,
    selectedItem: Category,
    addItem: Function,
    onFinishEditItem: Function,
    editItem: Function,
    showDialogContent: Function
}

class AddEditItemDialog extends Component<ItemPageProps,
    {
        pending: boolean,
        repeatPassword: string,
        id: any,
        disabled: boolean
    }> {

    constructor(p: any) {
        super(p);
        this.state = {
            title: '',
            comment: '',
            id: '',
            ...p.selectedItem,
            pending: false,
            disabled: false
        };
    }

    static defaultProps = {
        onFinishEditItem: () => 1
    }


    componentWillReceiveProps(nextProps: Readonly<ItemPageProps>, nextContext: any): void {
        checkError(nextProps, this.props, () => {
            this.setState({
                pending: false,
                disabled: false,
            });
        });
        if (nextProps.categoryItemAdded !== this.props.categoryItemAdded || nextProps.categoryItemEdited !== this.props.categoryItemEdited) {
            this.handleCancel();
            this.setState({
                pending: false,
                disabled: false,
            });
        }
    }

    private onChange = (e: any) => {
        const newState: any = {
            disabled: false,
            [e.target.name]: e.target.value
        };
        this.setState(newState);
    };


    private handleOk = async (e: any) => {
        const form: any = this.refs.form;
        form.submit();
    };

    private handleCancel = () => {
        this.props.showDialogContent(null);
    };

    private handleSubmit = (e: any) => {
        const form: any = this.refs.form;
        form.isFormValid().then(async (valid: any) => {
            if (valid) {
                this.setState({
                    pending: true,
                });
                if (this.state.id) {
                    await this.props.editItem({
                        ...this.state,
                    });
                } else {
                    await this.props.addItem({
                        ...this.state,
                    });
                }

                this.props.onFinishEditItem();
            } else {
                this.setState({
                    disabled: true,
                });
            }
        })
    }

    render() {
        const {title, comment, id, disabled, pending}: any = this.state;

        return (
            <React.Fragment>
                <DialogTitle
                    className={'modal-container'}>
                    <div className={'modal-title'}>{id ? 'Edit' : 'Add'} Category</div>
                    <IconButton className={'close'} onClick={this.handleCancel}>
                        <Icon>close</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent>

                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={(errors: any) => console.log(errors)}
                        className={'d-flex f-col'}
                    >
                        <div className={'m-5'}>
                            <TextValidator
                                className={'m-5 fullWidth'}
                                label="Title"
                                onChange={this.onChange}
                                name="title"
                                autoComplete={'off'}
                                value={title}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </div>
                        <div className={'m-5'}>
                            <TextValidator
                                className={'m-5 fullWidth'}
                                multiline={true}
                                rows={5}
                                label="Comment"
                                value={comment}
                                name={'comment'}
                                onChange={this.onChange}
                                margin="normal"
                            />
                        </div>

                    </ValidatorForm>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        className={'my-btn btn-primary'}
                        onClick={this.handleOk} disabled={disabled || pending}
                    >
                        Save
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    categoryItemEdited: state[moduleName].categoryItemEdited,
    categoryItemAdded: state[moduleName].categoryItemAdded,
    error: errorSelector(state),
});

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showDialogContent,
        addItem: addCategory,
        editItem: editCategory,
    }, dispatch)
);
const edit = connect(mapStateToProps, mapDispatchToProps)(AddEditItemDialog);
export default edit;
