import React, {Component} from 'react';
import './index.scss';
import {errorSelector, locationSelector, moduleName} from "../../../../../ducks/map";
import {addPole, editPole} from "../../../../../ducks/map/poles";
import {showDialogContent} from "../../../../../ducks/dialogs";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {isSuperADMINAdminSelector, isSuperAdminSelector} from "../../../../../ducks/auth";

import MainModalDialog, {TYPES} from "../main.modal";

class AddPoleDialog extends MainModalDialog {

    constructor(p: any) {
        super(p);
        this.title = 'Przęsła';
        this.type = TYPES.POLE;
        this.editTitle = false;
    }

    render() {

        return super._render();
    }
}

const mapStateToProps = (state: any) => ({
    itemsList: state[moduleName].polesList,
    error: errorSelector(state),
    isAdmin: isSuperADMINAdminSelector(state),
    location: locationSelector(state),
});

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showDialogContent,
        addPole,
        editItem: editPole,
    }, dispatch)
);
const edit = connect(mapStateToProps, mapDispatchToProps)(AddPoleDialog);
export default edit;
