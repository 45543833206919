const URL: string = window.location.protocol + '//' + window.location.hostname;
//export const API = process.env.NODE_ENV === 'development' ? URL + ':3009/' : '/';
export const API = 'http://164.90.209.199:3010/';
export const DATE_FORMAT = 'YYYY-MMM-DD hh:mm';

[
    {
        id: 1,
        tes: 'df',
    },
    {
        id: 2,
        tes: 'df',
    },
    {
        id: 3,
        tes: 'df',
    },
].reduce((acc: any, item: any) => ({ ...acc, [item.id]: item }), {});
console.log("------", process.env.NODE_ENV);
export const appName = 'matpark-app';
