import React, {Component} from 'react';
import {Switch, BrowserRouter, Route, Redirect} from 'react-router-dom';

import AdminPage from './pages/admin';
import MainPage from './pages/main';
import Login from './pages/auth/login/index';
import ResetPsw from './pages/auth/reset-psw';
import ForgotPsw from './pages/auth/forgot-psw';
import Register from './pages/auth/register';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {loadUser, userSelector, moduleName, USER_ROLE, isSuperAdminSelector} from './ducks/auth';

import {ConnectedRouter} from 'react-router-redux';
import history from './history';

class AppRoutes extends Component<{ user: any, isChecked: boolean, isSuperAdmin: boolean, loadUser: any },
    {}> {

    componentDidMount(): void {
        this.props.loadUser();
    }

    render() {
        const {user, isChecked, isSuperAdmin} = this.props;
        if (!isChecked) return null;
        const loader: any = document.getElementById('loader');
        if (loader) loader.style.display = 'none';

        return (
            <BrowserRouter>
                {
                    user ? (
                            <Switch>
                                <Route path='/' component={MainPage}/>
                            </Switch>
                        ) :
                        (
                            <Switch>
                                <Route path='/login' component={Login}/>
                                <Route path='/reset-psw' component={ResetPsw}/>
                                <Route path='/forgot-psw' component={ForgotPsw}/>
                                {/*<Route path='/register' component={Register}/>*/}
                                <Route exact path="*" render={() => (
                                    <Redirect to="/login"/>
                                )}/>
                            </Switch>
                        )
                }
            </BrowserRouter>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        loadUser,
    }, dispatch)
);
const mapStateToProps = (state: any) => ({
    user: userSelector(state),
    isSuperAdmin: isSuperAdminSelector(state),
    isChecked: state[moduleName].isChecked,
});
export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);

