import React, {Component} from 'react';

import './index.scss';


export default function Title({children}: any) {
    const _c = /*Array.isArray(children) && children.length === 2 ? children.join("") :*/children;
    return (
        <span title={_c} className={'text-overflow'}>{children}</span>
    )
}
