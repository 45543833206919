import {saga as authSaga} from '../ducks/auth'
import {saga as mapSaga} from '../ducks/map'
import {saga as adminSaga} from '../ducks/admin'
import {saga as dialogsSaga} from '../ducks/dialogs'
import {saga as tooltipSaga} from '../ducks/tooltip'
import {all} from 'redux-saga/effects'

export default function* rootSaga() {
    yield all([
        adminSaga(),
        authSaga(),
        dialogsSaga(),
        tooltipSaga(),
        mapSaga(),
    ])
}
