import React, {Component} from 'react';
import './index.scss';

import {
    locationSelector, locationStationsSelector,
    moduleName
} from "../../../../ducks/map";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import MainTable from "../MainTable";
import {showDialogContent} from "../../../../ducks/dialogs";
import {API} from "../../../../config";
import Title from "../../../../components/title";
import AddStationDialog from "../../../components/Map/map.container/add.station";
import {fetchLocationMOREStations, deleteStation} from "../../../../ducks/map/stations";

class SegmentsTable extends MainTable {

    constructor(p: any) {
        super(p);
        const columns: any = [//nazwa_ciagu_id, NAZWA_TAB, nazwa_linii
            {name: 'id', title: 'Id', getCellValue: (row: any) => <Title>{row.id}</Title>},
            {name: 'comment', title: 'Komentarz', getCellValue: (row: any) => <Title>{row.comment}</Title>},
            {
                name: 'nazw_stac',
                title: 'nazw_stac',
                getCellValue: (row: any) => <Title>{row.nazw_stac}</Title>
            },
            {name: 'num_eksp_s', title: 'num_eksp_s', getCellValue: (row: any) => <Title>{row.num_eksp_s}</Title>},
        ];
        const tableColumnExtensions: any = [
            {columnName: 'id', width: 200},
            {columnName: 'comment', width: 200},
            {columnName: 'nazw_stac', width: 220},
            {columnName: 'num_eksp_s', width: 220},
        ];
        this.state = {
            ...this.state,
            tableColumnExtensions,
            columns,
            title: 'Stations'
        };
    }

    componentDidMount(): void {
        super.componentDidMount();

    }


    protected URL = (): string => {
        return `${API}api/projects/${this.props.project ? this.props.project.id : -1}/stations`;
    };


    protected onEditItem = (item: any, onFinishEditItem: Function = () => false) => {
        const {showDialogContent} = this.props;
        showDialogContent(
            <AddStationDialog
                selectedItem={item}
                onFinishEditItem={() => this.callbacks.push(onFinishEditItem)}
            />
        );
    };


    render() {
        return super._render();
    }
}

const mapStateToProps = (state: any) => ({
    itemsList: state[moduleName].stationList,
    project: locationSelector(state),
    rows: locationStationsSelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showDialogContent,
        onLoadMoreItems: fetchLocationMOREStations,
        onDeleteItem: deleteStation,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(SegmentsTable);
