import React, {Component} from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {showDialogContent} from "../../../ducks/dialogs";
import {API} from "../../../config";
import Title from "../../../components/title";
import {deleteCategory, editCategory, addCategory, fetchMoreCategories} from "../../../ducks/admin/categories";
import {moduleName} from "../../../ducks/admin/config";
import MainTable from "../../home/tables/MainTable";
import AddEditItemDialog from "./add.edit.item";
import {locationSelector} from "../../../ducks/map";
import {Category} from "../../../entities";
import {categorySelector, errorSelector} from "../../../ducks/admin";


class CategoryTable extends MainTable {

    constructor(p: any) {
        super(p);
        const columns: any = [
            {name: 'title', title: 'Title', getCellValue: (row: any) => <Title>{row.title}</Title>},
            {
                name: 'comment',
                title: 'Comment',
                getCellValue: (row: any) => <Title>{row.comment}</Title>
            },
        ];
        const tableColumnExtensions: any = [
            {columnName: 'title', width: 200},
            {columnName: 'comment', width: 200},
        ];
        const title: any = 'Categories';
        this.state = {
            ...this.state,
            HAVE_NO_PROJECT: true,
            canAddItem: true,
            tableColumnExtensions,
            columns,
            title
        };
    }


    private handleChange = (item: Category, ev: any) => {
        this.props.onEditItem({...item});
        this.callbacks.push(() => 0)
    };

    protected onAddItem = (onFinishEditItem: Function = () => false) => {
        const {showDialogContent} = this.props;
        showDialogContent(
            <AddEditItemDialog
                selectedItem={new Category()}
                onFinishEditItem={() => this.callbacks.push(onFinishEditItem)}
            />
        );
    };

    componentDidMount(): void {
        super.componentDidMount();

    }


    protected URL = (): string => {
        return `${API}api/category`;
    };


    protected onEditItem = (item: any, onFinishEditItem: Function = () => false) => {
        const {showDialogContent} = this.props;
        showDialogContent(
            <AddEditItemDialog
                selectedItem={item}
                onFinishEditItem={() => this.callbacks.push(onFinishEditItem)}
            />
        );
    };


    render() {
        return super._render();
    }
}

const mapStateToProps = (state: any) => ({
    itemsList: state[moduleName].categoryList,
    project: locationSelector(state),
    error: errorSelector(state),
    rows: categorySelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showDialogContent,
        onLoadMoreItems: fetchMoreCategories,
        onDeleteItem: deleteCategory,
        onEditItem: editCategory
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(CategoryTable);
