import React, {Component} from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {showDialogContent} from "../../../ducks/dialogs";
import {fetchMoreLOGS} from "../../../ducks/admin/logs";
import {API} from "../../../config";
import Title from "../../../components/title";
import {moduleName} from "../../../ducks/admin/config";
import MainTable from "../../home/tables/MainTable";
import {locationPoisSelector, locationSelector} from "../../../ducks/map";
import {errorSelector} from "../../../ducks/admin";

class LogsTable extends MainTable {

    constructor(p: any) {
        super(p);
        const columns: any = [
            {name: 'ip', title: 'ip', getCellValue: (row: any) => <Title>{row.ip}</Title>},
            {
                name: 'user',
                title: 'user',
                getCellValue: (item: any) => (
                    <Title>{item.user ? item.user.email : ''} </Title>)
            },
            {
                name: 'action',
                title: 'action',
                getCellValue: (item: any) => (
                    <Title>{item.action}</Title>)
            },
            {name: 'description', title: 'description', getCellValue: (row: any) => <Title>{row.description}</Title>},
        ];
        const tableColumnExtensions: any = [
            {columnName: 'ip', width: 200},
            {columnName: 'action', width: 200},
            {columnName: 'user', width: 200},
            {columnName: 'description', width: 420},
        ];
        const title: any = 'Logs';
        this.state = {
            ...this.state,
            HAVE_NO_PROJECT: true,
            hasDeleteAction: false,
            hasEditAction: false,
            tableColumnExtensions,
            columns,
            title
        };
    }


    protected URL = (): string => {
        return `${API}api/admin/logs`;
    };

    render() {
        return super._render();
    }
}

const mapStateToProps = (state: any) => ({
    itemsList: state[moduleName].userList,
    project: locationSelector(state),
    error: errorSelector(state),
    rows: locationPoisSelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showDialogContent,
        onLoadMoreItems: fetchMoreLOGS,
        onDeleteItem: () => 0
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(LogsTable);
