import React, {Component} from 'react';
import './index.scss';
import Dialog from '@material-ui/core/Dialog';

import Button from '@material-ui/core/Button';
import {showDialogContent, contentSelector} from "../../../../ducks/dialogs";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";


class AlertDialog extends Component<{ showDialogContent: any, content: string },
    {}> {

    private handleClose = () => {
        this.props.showDialogContent(false);
    };

    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                className={'dialog-view'}
                open={!!this.props.content}
                onClose={this.handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                {this.props.content}
            </Dialog>
        );
    }
}

const mapStateToProps = (state: any) => ({
    content: contentSelector(state)
});
const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        showDialogContent,
    }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);

