import React, {Component} from 'react';

import './index.scss';
import {errorSelector, moduleName, toolltipInfo} from "../../ducks/tooltip";
import {connect} from "react-redux";


function Tooltip({tooltipInfo}: any) {
    if (!tooltipInfo) return null;
    return (
        <div className="tooltip" style={{left: tooltipInfo.left, top: tooltipInfo.top}}>
            <span className="tooltiptext">{tooltipInfo.content}</span>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    tooltipInfo: toolltipInfo(state),
    error: errorSelector(state),
});

const MapTooltip = connect(mapStateToProps)(Tooltip);
export default MapTooltip;
